import format from 'date-fns/format/index.js';
import { ProjectionConstantS } from '../../../Projection/S/Constant/module/ProjectionConstantS.js';
import { svg_icons } from '../../Stock/Labeling/S/Tmpl/svg_icons.js';
import {
  entries,
  filter,
  find,
  go,
  groupBy,
  head,
  html,
  join,
  last,
  map,
  pick,
  reduce,
  sel,
  sortBy,
  strMap,
  uniqueBy,
  zipWithIndexL,
} from 'fxjs/es';
import { makeDfProjectionListFilterHeader } from '../../Projection/List/S/tmpl.js';
import $dataStr from 'fxdom/es/dataStr.js';
import { svgs } from './constant.js';

function makeShipType(type, lang) {
  if (lang != 'kr') {
    if (type == 'parcel') return '해외';
    if (type == 'combined') return '해묶';
    return '해외';
  }
  if (type == 'parcel') return '택배';
  if (type == 'quick') return '퀵';
  if (type == 'visit') return '방문';
  if (type == 'partition') return '개별';
  if (type == 'combined') return '묶음';
}

function makeTaskStatusToKR(status) {
  if (status == 'ready') return '진행전';
  if (status == 'on') return '진행중';
  if (status == 'completed') return '완료';
}

function mergeProjectionStatus(status) {
  if (status == 'before_print') return status;
  if (status == 'printing') return status;
  if (status == 'before_deliver') return status;
  return 'delivering';
}

export function makeProjectionStatusToKR(status) {
  return ProjectionConstantS.STATUS[status]?.label || '';
}

export const makeCheckPointFilteringHtml = (states, is_ing, TASKS_GROUP, TASKS_GROUP_OUTSOURCING) => html`
  <div id="filter" style="margin:0;">
    <div class="search_bar">
      <form action="" id="search_bar" method="get">
        ${is_ing
          ? ''
          : html`
              <div class="calendar sd">
                <div class="s_col title">
                  출고일 선택
                  <div id="calendar"></div>
                </div>
              </div>
              <div class="sd ordering">
                <div class="s_col title">정렬</div>
                <div class="s_col">
                  <select name="order_by" id="">
                    <option value="" ${!states.order_by ? 'selected' : ''}>출고일 기준</option>
                    <option value="ordered_at" ${states.order_by == 'ordered_at' ? 'selected' : ''}>
                      주문일순
                    </option>
                    <option value="image_task_at" ${states.order_by == 'image_task_at' ? 'selected' : ''}>
                      이미지작업 완료순
                    </option>
                  </select>
                </div>
              </div>
            `}
        ${makeDfProjectionListFilterHeader(states)}
        ${(!states.is_team && !states.is_me) || states.outsourcing_worker_policy
          ? html`
              <div class="search_status sd">
                <div class="s_col title">주문서 상태</div>
                <div class="s_col">
                  <label>
                    <input type="radio" name="status" value="" ${!states.status ? 'checked' : ''} />
                    <span>모든 주문서</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="ing"
                      ${states.status == 'ing' ? 'checked' : ''}
                    />
                    <span>작업중+오늘출고</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="before_print"
                      ${states.status == 'before_print' ? 'checked' : ''}
                    />
                    <span>제작준비중</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="printing"
                      ${states.status == 'printing' ? 'checked' : ''}
                    />
                    <span>제작중</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="printing_and_import"
                      ${states.status === 'printing_and_import' ? 'checked' : ''}
                    />
                    <span>제작중+제품입고</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="printing_and_export"
                      ${states.status === 'printing_and_export' ? 'checked' : ''}
                    />
                    <span>제작중+배송입고</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="complete_and_export"
                      ${states.status === 'complete_and_export' ? 'checked' : ''}
                    />
                    <span>제작완료+배송입고</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="before_deliver"
                      ${states.status == 'before_deliver' ? 'checked' : ''}
                    />
                    <span>제작완료</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="delivering"
                      ${states.status == 'delivering' ? 'checked' : ''}
                    />
                    <span>배송중</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="delivering_today"
                      ${states.status == 'delivering_today' ? 'checked' : ''}
                    />
                    <span>오늘 출고된</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="before_confirm"
                      ${states.status == 'before_confirm' ? 'checked' : ''}
                    />
                    <span>배송완료</span>
                  </label>
                </div>
                <div class="s_col">
                  <label>
                    <input
                      type="radio"
                      name="status"
                      value="confirmed"
                      ${states.status == 'confirmed' ? 'checked' : ''}
                    />
                    <span>구매확정</span>
                  </label>
                </div>
              </div>
            `
          : ''}
        <div class="search_status2 sd">
          <div class="s_col title">주문서 상태2</div>
          <div class="s_col">
            <label>
              <input type="checkbox" name="is_cancel" ${states.is_cancel ? 'checked' : ''} />
              <span>취소 주문서 (취소요청 + 취소 완료)</span>
            </label>
          </div>
        </div>
        <div class="search_status2 sd">
          <div class="s_col title">긴급출고</div>
          <div class="s_col">
            <label>
              <span>고객요청</span>
              <select name="important" style="margin-left: 10px">
                <option value="">선택안함</option>
                <option value="true" ${states.important == 'true' ? 'selected' : ''}>긴급</option>
                <option value="false" ${states.important == 'false' ? 'selected' : ''}>긴급아닌</option>
              </select>
            </label>
          </div>
          <div class="s_col">
            <label>
              <span>내부전용</span>
              <select name="internal_important" style="margin-left: 10px">
                <option value="">선택안함</option>
                <option value="true" ${states.internal_important == 'true' ? 'selected' : ''}>긴급</option>
                <option value="false" ${states.internal_important == 'false' ? 'selected' : ''}>
                  긴급아닌
                </option>
              </select>
            </label>
          </div>
        </div>
        <div class="search_status2 sd">
          <div class="s_col title">태스크 상태</div>
          <div class="s_col">
            <label>
              <input
                class="query arr"
                type="checkbox"
                name="task_status"
                value="ready"
                ${states.task_status?.indexOf('ready') > -1 ? 'checked' : ''}
              />
              <span>진행전</span>
            </label>
          </div>
          <div class="s_col">
            <label>
              <input
                class="query arr"
                type="checkbox"
                name="task_status"
                value="on"
                ${states.task_status?.indexOf('on') > -1 ? 'checked' : ''}
              />
              <span>진행중</span>
            </label>
          </div>
          <div class="s_col">
            <label>
              <input
                class="query arr"
                type="checkbox"
                name="task_status"
                value="completed"
                ${states.task_status?.indexOf('completed') > -1 ? 'checked' : ''}
              />
              <span>완료</span>
            </label>
          </div>
        </div>
        <div class="search_status2 sd">
          <div class="s_col title">배송방식</div>
          <div class="s_col">
            <label
              ><input
                class="query arr"
                type="checkbox"
                name="shipping_types"
                value="parcel"
                ${states.shipping_types?.indexOf('parcel') > -1 ? 'checked' : ''}
              /><span>택배</span></label
            >
          </div>
          <div class="s_col">
            <label
              ><input
                class="query arr"
                type="checkbox"
                name="shipping_types"
                value="combined"
                ${states.shipping_types?.indexOf('combined') > -1 ? 'checked' : ''}
              /><span>묶음배송</span></label
            >
          </div>
          <div class="s_col">
            <label
              ><input
                class="query arr"
                type="checkbox"
                name="shipping_types"
                value="partition"
                ${states.shipping_types?.indexOf('partition') > -1 ? 'checked' : ''}
              /><span>개별배송</span></label
            >
          </div>
          <div class="s_col">
            <label
              ><input
                class="query arr"
                type="checkbox"
                name="shipping_types"
                value="quick"
                ${states.shipping_types?.indexOf('quick') > -1 ? 'checked' : ''}
              /><span>퀵</span></label
            >
          </div>
          <div class="s_col">
            <label
              ><input
                class="query arr"
                type="checkbox"
                name="shipping_types"
                value="visit"
                ${states.shipping_types?.indexOf('visit') > -1 ? 'checked' : ''}
              /><span>방문</span></label
            >
          </div>
        </div>
        <div class="search_status2 sd">
          <div class="s_col title">라벨부착 포함</div>
          <div class="s_col">
            <label>
              <input
                type="checkbox"
                name="is_labeling"
                value="ready"
                ${states.is_labeling?.indexOf('true') > -1 ? 'checked' : ''}
              />
              <span>포함</span>
            </label>
          </div>
        </div>
        ${states.outsourcing_worker_policy
          ? ``
          : html`
              <div class="sd tasks_group">
                <div class="s_col title">항목 선택</div>
                <div class="s_col" style="width: 90%">
                  <div class="s_col group_name" val="">선택 해제</div>
                  ${strMap(
                    (group) => html` <div class="s_col group_name" val="${group.name}">${group.name}</div> `,
                    [...TASKS_GROUP, ...TASKS_GROUP_OUTSOURCING],
                  )}
                </div>
              </div>
            `}
        <div class="search_title sd">
          <div class="s_col title">태스크 작업명</div>
          <div class="s_col" style="width: 90%">
            ${go(
              states.titles.concat(map((title) => ({ title }), states.title)),
              uniqueBy(sel('title')),
              map(({ title }) => title.toUpperCase().replace(/\s/g, '')),
              sortBy((t) => {
                if (t.indexOf('AS') != -1) return 99;
                if (t.indexOf('외주검수') != -1) return 100;

                if (t == '재고확인') return 1;
                if (t == '라벨부착') return 2;
                if (t == '배송') return 3;
                if (t == '반품접수') return 4;
                if (t == '불량') return 5;
                if (t == '반품도착확인') return 6;
                if (t == 'DTP') return 7;
                if (t == '나염') return 8;

                const find_task_group = go(
                  TASKS_GROUP,
                  zipWithIndexL,
                  find(([, { values }]) => find((v) => t == v, values)),
                );
                if (find_task_group) return 11 + find_task_group[0] * 0.001;

                if (t == 'FLEX') return 12;
                if (t.indexOf('전사') != -1) return 13;
                if (t.indexOf('PRESS') != -1) return 14;
                if (t.indexOf('케이스') != -1) return 15;
                if (t == '봉제') return 16;
                if (t == '자수') return 17;
                if (t == '페브릭포스터봉제') return 20;
                if (t == '라이프백') return 21;
                if (t == '봉제') return 22;
                if (t == '이미지작업') return 25;
                if (t.indexOf('출고일') != -1) return 26;
                if (t.indexOf('금액') != -1) return 27;
                if (t.indexOf('고객') != -1) return 28;
                if (t == '배송전') return 29;
                if (t == '완료') return 30;

                const find_task_group_outsourcing = go(
                  TASKS_GROUP_OUTSOURCING,
                  zipWithIndexL,
                  find(([, { values }]) => find((v) => t == v, values)),
                );
                if (find_task_group_outsourcing) return 1000 + find_task_group_outsourcing[0] * 0.001;
                if (t.indexOf('[외주]') > -1) return 999;
                return 31;
              }),
              strMap(
                (title) => html`
                  <div class="s_col">
                    <label class="task-title-check">
                      <input
                        type="checkbox"
                        name="title"
                        value="${title}"
                        ${find((tit) => tit == title, states.title) ? 'checked' : ''}
                      />
                      <span>${title}</span>
                    </label>
                  </div>
                `,
              ),
            )}
          </div>
        </div>
        <div class="search_result search_btn">
          <a href="/checkpoint${is_ing ? `/ing${is_ing ? '/2' : ''}` : ''}">
            <button class="init" type="button">초기화</button>
          </a>
          <button class="go" type="button">검색</button>
        </div>
      </form>
    </div>
  </div>
`;

const getShortenDates = (date_arr) => {
  if (date_arr?.length) {
    const parseDate = (d) => {
      const date = d.split('-');
      return { year: date[0], month: date[1], day: date[2] };
    };
    if (date_arr.length === 1) {
      const date = parseDate(date_arr[0]);
      return `${date.month}월 ${date.day}일`;
    } else {
      const shorten_dates_arr = [];
      go(
        date_arr,
        map(parseDate),
        (dates) =>
          reduce(
            (acc, d) => {
              if (!acc.start.month) {
                Object.assign(acc.start, d);
                Object.assign(acc.prev, d);
              } else {
                if (d.month === acc.prev.month) {
                  if (!(d.day - acc.prev.day === 1)) {
                    Object.assign(acc.end, acc.prev);
                    shorten_dates_arr.push(acc);
                    acc = { start: { ...d }, prev: { ...d }, end: {} };
                  }
                } else {
                  Object.assign(acc.end, acc.prev);
                  shorten_dates_arr.push(acc);
                  acc = { start: { ...d }, prev: { ...d }, end: {} };
                }
              }
              Object.assign(acc.prev, d);
              return acc;
            },
            { start: {}, prev: {}, end: {} },
            dates,
          ),
        (x) => {
          if (!Object.keys(x.end).length) {
            Object.assign(x.end, x.prev);
            shorten_dates_arr.push(x);
          }
        },
      );

      return go(
        shorten_dates_arr,
        map((date_obj) => {
          if (date_obj.start.day === date_obj.end.day) {
            return `${Number(date_obj.start.month)}월 ${Number(date_obj.start.day)}일`;
          } else {
            return `${Number(date_obj.start.month)}월 ${Number(date_obj.start.day)}~${Number(
              date_obj.end.day,
            )}일`;
          }
        }),
        join(' / '),
      );
    }
  }
};

export const makeCheckPointDashBoardAndTableHtml = ({
  rows_data_arr,
  task_title,
  important,
  internal_important,
  url,
  query_str,
  is_ing = false,
  is_print_mode = true,
  is_labeling,
}) => html` <div class="task_workers">
  ${strMap(
    ([name, rows]) => html`
      <div class="task_worker iv" worker_id="${rows[0].worker_id}">
        <div class="header">
          <h3 class="task_worker_name">${name}</h3>
          <div class="t_statuses">
            ${makeCheckPointStatusNumberBoard(
              rows,
              task_title,
              name,
              important,
              internal_important,
              url,
              query_str,
            )}
          </div>
        </div>
        ${!is_ing
          ? makeCheckPointTableHtml({ rows, url, is_print_mode, is_before_deliver_at: false, is_labeling })
          : ''}
      </div>
    `,
    rows_data_arr,
  )}
</div>`;

const compareDay = (before_deliver_at) => {
  const after1 = moment().add(1, 'day');
  const after2 = moment(before_deliver_at).add(2, 'day');
  const after3 = moment(before_deliver_at).add(3, 'day');
  if (
    new Date(`${after1.year()}-${after1.month() + 1}-${after1.date()}`) >
    new Date(`${after3.year()}-${after3.month() + 1}-${after3.date()}`)
  ) {
    return '3day';
  } else if (
    new Date(`${after1.year()}-${after1.month() + 1}-${after1.date()}`) >
    new Date(`${after2.year()}-${after2.month() + 1}-${after2.date()}`)
  ) {
    return '2day';
  } else {
    return false;
  }
};

export const makeCheckPointTableHtml = ({
  rows,
  url,
  is_print_mode = true,
  is_before_deliver_at,
  is_labeling,
  is_inhouse,
}) => {
  return html`
    <div
      class="rows iv tasks"
      ${is_before_deliver_at ? ' style="overflow-y:visible;max-height:none;margin-bottom: 60px"' : ''}
    >
      <table>
        <thead>
          <tr>
            ${is_inhouse
              ? html` <th class="header_inhouse_shipping_type">발송 위치</th>`
              : html`<th class="header_label_print label_print_title ${is_print_mode ? '' : 'hidden'}">
                  라벨인쇄 ${svg_icons.yellow_circle}${svg_icons.blue_circle}${svg_icons.green_circle}
                </th>`}
            <th class="header_task_change_mode task_status_change_title ${is_print_mode ? 'hidden' : ''}">
              태스크 상태변경
            </th>
            <th class="header_due_at">출고일</th>
            <th class="header_ordered_at">주문일</th>
            <th class="header_shipping">배송</th>
            <th class="header_projection_id">주문서</th>
            <th class="header_qty">수량</th>
            <th class="header_projection_status">상태</th>
            ${is_before_deliver_at ? html`<th class="header_before_deliver_at">제작완료일</th>` : ''}
            ${is_labeling ? html`<th class="header_task">라벨부착</th>` : ''}
            <th class="header_task">태스크</th>
            <th class="header_task">이미지작업자</th>
            <th class="header_issue">이슈</th>
          </tr>
        </thead>
        <tbody>
          ${go(
            rows,
            strMap((row) =>
              makeCheckPointTableRow({
                row,
                url,
                is_print_mode,
                is_before_deliver_at,
                is_labeling,
                is_inhouse,
              }),
            ),
          )}
        </tbody>
      </table>
    </div>
  `;
};

export const makeCheckPointTableRow = ({
  row,
  url,
  is_print_mode = true,
  is_before_deliver_at,
  is_labeling,
  is_inhouse,
}) => {
  return html`
    <tr
      status="${mergeProjectionStatus(row.p_status)}"
      important="${row.important}"
      internal-important="${row.internal_important}"
      t_status="${row.t_status}"
    >
      ${is_inhouse
        ? html`<td class="${row.customer_order_crew_inhouse_is_hidden ? 'private' : 'company'}">
            ${row.customer_order_crew_inhouse_is_hidden ? '개인' : '사내'}
          </td>`
        : html`
            <td class="label_print_btn ${is_print_mode ? '' : 'hidden'}" data-fx-json="${$dataStr(row)}">
              ${svg_icons.print_fill}
            </td>

            <td class="status_switch ${is_print_mode ? 'hidden' : ''}">
              <div class="rating">
                <form class="rating-form">
                  <input
                    type="radio"
                    name="rating"
                    value="ready"
                    task_id=${row.t_id}
                    projection_id="${row.id}"
                    ${row.t_status === 'ready' ? `checked class='checked'` : ''}
                  />
                  ${svgs.sad_face}
                  <input
                    type="radio"
                    name="rating"
                    value="on"
                    task_id=${row.t_id}
                    projection_id="${row.id}"
                    ${row.t_status === 'on' ? `checked class='checked'` : ''}
                  />
                  ${svgs.neutral_face}
                  <input
                    type="radio"
                    name="rating"
                    value="completed"
                    task_id=${row.t_id}
                    projection_id="${row.id}"
                    ${row.t_status === 'completed' ? `checked class='checked'` : ''}
                  />
                  ${svgs.happy_face}
                </form>
              </div>
            </td>
          `}

      <td class="cell_due_at" important=${row.important} internal-important="${row.internal_important}">
        ${moment(row.due_at).format('YY/MM/DD')}
      </td>
      <td class="cell_ordered_at">${moment(row.ordered_at).format('YY/MM/DD HH:mm')}</td>
      <td>${makeShipType(row.shipping_type, row.lang)}</td>
      <td class="cell_projection_id">
        <a href="/projection/detail/${row.id}" target="_blank">${row.id}</a>
      </td>
      <td class="cell_quantity">${row.quantity}</td>
      <td class="cell_projection_status" status=${row.p_status}>${makeProjectionStatusToKR(row.p_status)}</td>
      ${is_before_deliver_at
        ? html`<td class="cell_before_deliver_at" is_72="${compareDay(row.before_deliver_at)}">
            ${row.before_deliver_at == null ? '' : moment(new Date(row.before_deliver_at)).format('YY/MM/DD')}
          </td>`
        : ''}
      ${is_labeling
        ? row.labeling_task_id
          ? html` <td class="cell_task" status=${row.deliver_task_status}>
              <a
                href="${row.deliver_task_status == 'completed'
                  ? `${url}?order_by=DESC&status=completed&task_id=${row.deliver_task_id}`
                  : `${url}?order_by=DESC&status=ready_on&task_id=${row.deliver_task_id}`}"
                target="_blank"
                task_status="${makeTaskStatusToKR(row.deliver_task_status)}"
                task_id="${row.deliver_task_id}"
              ></a>
            </td>`
          : html`<td class="cell_task">태스크없음</td>`
        : ''}
      <td class="cell_task" status=${row.t_status}>
        <a
          href="${row.t_status == 'completed'
            ? `${url}?order_by=DESC&status=completed&task_id=${row.t_id}`
            : `${url}?order_by=DESC&status=ready_on&task_id=${row.t_id}`}"
          target="_blank"
          task_status="${makeTaskStatusToKR(row.t_status)}"
          task_id="${row.t_id}"
        ></a>
      </td>
      <td class="image_worker_name">${row.image_worker_name || '할당자 없음'}</td>
      <td class="t_issue_count" t_issue_count=${row.t_issue_count}>
        <a
          href="${row.t_status == 'completed'
            ? `${url}?order_by=DESC&status=completed&task_id=${row.t_id}`
            : `${url}?order_by=DESC&status=ready_on&task_id=${row.t_id}`}"
          target="_blank"
          >${row.t_issue_count ? '이슈' : ''}</a
        >
      </td>
    </tr>
  `;
};

export const makeCheckPointTaskTitlesHtml = (data, states, is_ing) => {
  const is_cancel = states.is_cancel ? 'is_cancel' : 'none';
  const important = states.important;
  const internal_important = states.internal_important;
  const prj_status = states.status || '';
  const url = states.outsourcing_worker_policy ? '/task/my_list' : '/task/list';
  const s_at = states.due_ats?.length ? format(new Date(head(states.due_ats)), 'yyyy.MM.dd hh:mm') : void 0;
  const end_date = new Date(last(states.due_ats));
  end_date.setDate(end_date.getDate() + 1);
  const e_at = states.due_ats?.length ? format(end_date, 'yyyy.MM.dd hh:mm') : void 0;
  const query_str = $.param({
    ...pick(
      [
        'lang_kr',
        'lang_en',
        'lang_jp',
        'merged_type',
        'collabo_type',
        'shopify_merged_type',
        'shipping_types',
        'marketplace_ids',
        'is_biz',
        'goods_type_id',
      ],
      states,
    ),
    prj_status,
    is_cancel,
    s_at,
    e_at,
    checkpoint_ats: states.due_ats,
  });

  return html`
    <div
      class="task_titles"
      url="${url}"
      important="${important}"
      internal_important="${internal_important}"
      query_str="${query_str}"
      is_ing="${is_ing}"
    >
      <!--   <h2>
        <span style="background-color: #4cc37f;padding: 2px 4px;">완료된 태스크</span>는 배송중, 배송완료,
        구매확정 주문서의 경우 카운트 되지 않을수 있습니다.
      </h2>-->
      <h1 class="dates">${getShortenDates(states.due_ats)}</h1>
      <div class="task_title_contents">
        ${strMap(
          ([title, task_workers]) => html`
            <div class="task_title iv">
              ${!is_ing
                ? html`
                    <div class="toggle_task_complete_mode">
                      <input class="toggle_state" type="checkbox" />
                      <div class="indicator">${svg_icons.toggle_indicator}</div>
                    </div>
                    <span class="title_task_complete_mode">태스크 상태변경</span>
                  `
                : ''}
              <h2 class="task_title_name">${title}</h2>
              ${!is_ing
                ? html`<span class="title_refresh_btn">목록 새로고침</span>
                    <div class="refresh_btn">${svg_icons.refresh}</div>`
                : ''}
              ${makeCheckPointDashBoardAndTableHtml({
                rows_data_arr: entries(task_workers),
                task_title: title,
                important,
                internal_important,
                url,
                query_str,
                is_ing,
                is_print_mode: true,
                is_labeling: states.is_labeling == 'true',
              })}
            </div>
          `,
          data,
        )}
      </div>
    </div>
  `;
};

export const makeCheckPointStatusNumberBoard = (
  rows,
  title,
  name,
  important,
  internal_important,
  url,
  query_str,
) => {
  const t_status_g = groupBy(sel('t_status'), rows);
  t_status_g.ready = t_status_g.ready || [];
  t_status_g.on = t_status_g.on || [];
  t_status_g.completed = t_status_g.completed || [];
  const is_show_all = important != 'true' && internal_important != 'true';
  const is_show_important = important == 'true' || is_show_all;
  const is_show_internal_important = internal_important == 'true' || is_show_all;
  const on_im = filter(sel('important'), t_status_g.on);
  const ready_im = filter(sel('important'), t_status_g.ready);
  const completed_im = filter(sel('important'), t_status_g.completed);
  const ready_internal_im = filter(sel('internal_important'), t_status_g.ready);
  const on_internal_im = filter(sel('internal_important'), t_status_g.on);
  const completed_internal_im = filter(sel('internal_important'), t_status_g.completed);
  const task_title = title || '';
  const worker_info = name || '';
  const q = `&at_opt=pj.due_at&task_title=${task_title}&worker_info=${worker_info}&${query_str}`;

  return html`
    ${is_show_important
      ? html`
          <div class="t_status important" status="ready" len=${ready_im.length}>
            <a href="${url}?important=true&order_by=ASC&status=ready_on&status2=ready${q}" target="_blank"
              >${ready_im.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_internal_important
      ? html`
          <div class="t_status internal-important" status="ready" len=${ready_internal_im.length}>
            <a
              href="${url}?internal_important=true&order_by=ASC&status=ready_on&status2=ready${q}"
              target="_blank"
              >${ready_internal_im.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_all
      ? html`
          <div class="t_status" status="ready" len=${t_status_g.ready.length}>
            <a href="${url}?order_by=ASC&status=ready_on&status2=ready${q}" target="_blank"
              >${t_status_g.ready.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_important
      ? html`
          <div class="t_status important" status="on" len=${on_im.length}>
            <a href="${url}?important=true&order_by=ASC&status=ready_on&status2=on${q}" target="_blank"
              >${on_im.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_internal_important
      ? html`
          <div class="t_status internal-important" status="on" len=${on_internal_im.length}>
            <a
              href="${url}?internal_important=true&order_by=ASC&status=ready_on&status2=on${q}"
              target="_blank"
              >${on_internal_im.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_all
      ? html`
          <div class="t_status" status="on" len=${t_status_g.on.length}>
            <a href="${url}?order_by=ASC&status=ready_on&status2=on${q}" target="_blank"
              >${t_status_g.on.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_important
      ? html`
          <div class="t_status important" status="completed" len=${completed_im.length}>
            <a href="${url}?important=true&order_by=ASC&status=completed${q}" target="_blank"
              >${completed_im.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_internal_important
      ? html`
          <div class="t_status internal-important" status="completed" len=${completed_internal_im.length}>
            <a href="${url}?internal_important=true&order_by=ASC&status=completed${q}" target="_blank"
              >${completed_internal_im.length}</a
            >
          </div>
        `
      : ''}
    ${is_show_all
      ? html`
          <div class="t_status" status="completed" len=${t_status_g.completed.length}>
            <a href="${url}?order_by=ASC&status=completed${q}" target="_blank"
              >${t_status_g.completed.length}</a
            >
          </div>
        `
      : ''}
  `;
};
