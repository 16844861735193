import { chunk, flatL, go, map, rangeL } from 'fxjs/es';

// -> [[item, item], [item, item] ...]
export const stackVertically = (print_items, { max_print_per_paper }) => {
  const sheet_count = go(print_items.length, (n) => n / max_print_per_paper, Math.ceil);
  const slice_chunks = go(print_items, flatL, chunk(sheet_count));

  return go(
    rangeL(sheet_count),
    map((i) => map((slices) => slices[i], slice_chunks)),
  );
};

export const stackHorizontally = (print_items, { max_print_per_paper }) => {
  return chunk(max_print_per_paper, print_items);
};
