import '../../../../../css/df/style.styl';
import '../../base_modules.js';
import '../../../../sh/app/all/i18next.config.js';
import '../../lazyload.js';
import '../../resize_sensor.js';
import './script1.js';
import '../../../../sh/app/df/base_product.js';
import '../../../../sh/app/df/image.js';
import '../../../../sh/app/df/help.js';

import '../../../../sh/app/df/df.stock.js';
import '../../../../sh/app/df/df.order.js';
import '../../../../sh/app/df/df.receipt_document.tmpl.js';

import '../../mp/mp.frame.js';
import '../../mp/mp.maker.conf.util.js';
import '../../mp/mp.maker.super_menu.js';
import '../../mp/mp.maker.range_slider.js';
import '../../mp/mp.maker.base_product_chooser.js';
import '../../mp/mp.maker.product.js';
import '../../mp/mp.maker.lock.js';
import '../../mp/mp.maker.pinch_mode.js';
import '../../mp/mp.maker.tooltip_canvas.js';
import '../../mp/mp.maker.image_color.js';
import '../../mp/mp.maker.frames.image.js';
import '../../mp/mp.maker.frames.editor.js';
import '../../mp/mp.maker.undo.js';
import '../../mp/mp.maker.menu.js';
import '../../mp/mp.maker.zoom.js';
import '../../mp/mp.maker.picker.js';
import '../../mp/mp.maker.js';

import '../df.config.js';
import '../df.head.js';
import '../df.worker.js';
import '../df.folder.js';
import '../df.task.js';
import '../df.task.task_set.js';
import '../df.issue.js';
import '../df.projection.pc_preview.js';
import '../df.projection.detail_payment.js';
import '../df.projection.detail_user_projections.js';
import '../df.projection.detail.js';
import '../df.settlement.js';
import '../order_align.js';
import '../df.stock.js';
import '../df.order.js';
import '../df.receipt_document.js';
import '../df.bks.js';
import '../../../../../routes/base.js';
import '../../../../../routes/df.js';
import '../../../../../routes/mp.js';

import './start_frame.js';
import '../../mp/entry/sentry.js';
