import { PriceS } from '../../../../../modules/Price/S/Function/module/PriceS.js';

!(function (LF) {
  G.df.bks = {};
  G.df.bks.complete = (bk_id) => $.attr($(`#bks_list .tr[bk_id="${bk_id}"]`), { is_complete: true });

  const banksNumbers2 = {
    '03760104041747': '국민은행 037601-04-041747',
    1005702680981: '우리은행 1005-702-680981',
    '07817423401013': '기업은행 078-174234-01-013',
  };
  G.df.bks.bank = (bk) => banksNumbers2[bk.accountnum].substr(0, 2);

  $.frame.defn_frame({
    frame_name: 'df.bks',
    page_name: 'df.bks',
    appending: __(
      $.on2('click', '.tr[is_complete="false"] .hand', function (e) {
        const id = $.attr(e.currentTarget, 'projection_id');
        return G.df.projection.detail.open({ id, lang: 'kr' }, 'projection.detail.payment');
      }),
      $.on2('click', '.tr[is_complete="false"] .open_projection_detail', function (e) {
        const bk = box.sel(e.currentTarget);
        const id = $.attr(e.currentTarget, 'projection_id');
        const projection_payment_id = $.attr(e.currentTarget, 'projection_payment_id');
        return G.df.projection.detail.open(
          { id, lang: 'kr' },
          'projection.detail.payment',
          bk,
          projection_payment_id,
        );
      }),
      $.on2('click', '.complete', function (e) {
        const bk = box.sel(e.currentTarget);
        const id = _p.v(bk, '_.projection_payment.projection_id');
        if (id) return G.df.projection.detail.open({ id, lang: 'kr' }, 'projection.detail.payment');
      }),
      $.on2('keyup', '.td input[type="text"]', async function (e) {
        if (e.keyCode != 13) return;
        const bk = box.sel(e.currentTarget);
        const projection_payment_id = $.val(e.currentTarget);
        const { id } = await $.get('/@api/projection/take_by_pp_id', {
          pp_id: projection_payment_id,
        });
        if (!id)
          return $.alert(
            '없는 ID이거나 무통장 입금이 아닙니다.<br>많이 복잡한 상황이면 개발팀에 문의해 주세요 :>',
          );
        delete bk.projection_payment_id;
        await G.df.projection.detail.open(
          { id, lang: 'kr' },
          'projection.detail.payment',
          bk,
          projection_payment_id,
        );
        $.val($.find(e.delegateTarget, '.td input'), '');
      }),
      $.on2('keyup', 'form input[type="text"]', function (e) {
        e.keyCode == 13 && _p.go(e.delegateTarget, $.find1('.submit'), $.trigger('click'));
      }),
      $.on2('click', 'form .reset', function (e) {
        _p.go(e.currentTarget, $.closest('form'), $.find('[name]:not([name="status"])'), $.val(''));
      }),
      $.on2('click', 'form .submit', function (e) {
        location.href = '/bks/list?' + _p.go(e.currentTarget, $.closest('form'), G.$form_$to_attrs, $.param);
      }),
      $.on2('click', '.show_hide button', async function (e) {
        const bk = box.sel(e.currentTarget);
        const is_hidden = (bk.is_hidden = !bk.is_hidden);
        _p.go(e.currentTarget, $.closest('.tr'), $.attr({ is_hidden }));
        await $.post('/@api/bks/show_hide', {
          id: bk.id,
          is_hidden,
        });
      }),
    ),
  });

  $.frame.defn_page({
    page_name: 'df.bks',
    tabs: [
      {
        tab_name: 'df.bks',
        infinite: function (don_tab) {
          return $.frame.infi($.find1(don_tab, '.tbody'), {
            limit: 50,
            template: (bk) => pug`
            .tr[_sel="bks->(#${bk.id})" bk_id="${bk.id}" is_hidden="${
              bk.is_hidden
            }" is_complete="${!!bk.projection_payment_id}"].infi_item
              .td ${bk.id}
              .td ${bk.bkdatetime}
              .td ${bk.bkjukyo}
              .td ${PriceS.pricify(bk.bkinput)}
              .td ${bk.bank}
              .td 
                <button type="button" class="complete">연결됨</button> 
                <button type="button" class="ready">연결 대기</button> 
              .td 
                input[type="text" placeholder="무통장 결제ID"]
              .td ${_sum(
                bk._.projection_payments,
                (pp) => `
                <div>
                  <button type="button" class="open_projection_detail" projection_id="${
                    pp.projection_id
                  }" projection_payment_id="${pp.id}" bk_id="${bk.id}">
                    <span class="name">${(pp.deposit_user_name || '').substr(0, 6)}</span>&nbsp;|&nbsp;
                    <span class="amount">${PriceS.pricify(pp.amount)}</span>&nbsp;|&nbsp;
                    ${(pp.bank_name || '').substr(0, 2)} &nbsp;|&nbsp;
                    #${pp.projection_id} &nbsp;|&nbsp;
                    ${moment(pp.created_at).format('YY.MM.DD HH:mm')}
                  </button>
                  <button type="button" class="hand" projection_id="${pp.projection_id}">수동</button>
                </div>
              `,
              )}
              .td ${moment(bk.created_at).format('YY.MM.DD HH:mm')} 
              .td.show_hide 
                <button type="button" class="show">복구</button>
                <button type="button" class="hide">숨김</button>`,
            data_func: function (offset, limit) {
              return _p.go(
                $.get(
                  '/@api/bks/list',
                  _p.extend(_p.go(don_tab, $.find1('form'), G.$form_$to_attrs), {
                    offset: offset,
                    limit: limit,
                  }),
                ),
                function (bks) {
                  window.box.push.apply(null, ['bks'].concat(bks));
                  return bks;
                },
              );
            },
          });
        },
      },
    ],
  });
})({});
