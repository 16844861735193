import { filter, go, html, join, map, pluck, range, split, strMap } from 'fxjs/es';

export const template_meta_editor = ({
  base_product_size_id,
  base_product_id,
  template_meta,
  base_product_faces,
  template_length,
}) => {
  template_meta = template_meta || {
    dpi: 300,
    need_trim: false,
    version: 'v20230902',
    line_width: 0,
    mimetype: 'image/png',
    stroke_color: '',
    mask_url: '',
    top_layer_url: '',
    black_and_white_outline_layer_url: '',
    size: { width: 100, height: 100 },
    text: {
      height: 1,
      is_separate: false,
      file_name: 'projection_id_up_id_base_product_name_base_product_color_name_quantityea',
      info_arr_in_image: [],
    },
    items: go(
      base_product_faces,
      map((bpf) => {
        return {
          base_product_face_id: bpf.id,
          bpf_name: bpf.name,
          top: 0,
          left: 0,
          width: 100,
          height: 100,
        };
      }),
    ),
  };
  try {
    if (template_length > 1) {
      template_meta = go(
        range(template_length),
        map(() => {
          const new_template_meta = JSON.parse(JSON.stringify(template_meta));
          const bpf_names = go(new_template_meta.items, pluck('bpf_name'), join(','));

          const str_bpf_names = window.prompt('bpf_names?', bpf_names);
          const selected_bpf_names = go(str_bpf_names, split(','));
          new_template_meta.items = go(
            new_template_meta.items,
            filter((item) => selected_bpf_names.includes(item.bpf_name)),
          );
          return new_template_meta;
        }),
      );
    }
  } catch (e) {}

  if (!Array.isArray(template_meta)) template_meta = [template_meta];
  return html`
    <div class="wrapper">
      ${strMap(
        (template_meta) => html`
          <div
            class="bps_template_meta_editor"
            data-base_product_id="${base_product_id}"
            data-base_product_size_id="${base_product_size_id}"
          >
            <form>
              <label for="">TEMPLATE</label>
              <div class="wrapper">
                <label>version: </label>
                <input type="text" name="version" value="${template_meta.version || ''}" />
                <label>trim: </label>
                <input type="checkbox" name="need_trim" ${template_meta.need_trim ? 'checked' : ''} />
                <label>with_color: </label>
                <input type="checkbox" name="with_color" ${template_meta.with_color ? 'checked' : ''} />
                <label>검은색 이미지: </label>
                <input
                  type="checkbox"
                  name="is_black_content"
                  ${template_meta.is_black_content ? 'checked' : ''}
                />
                <label>좌우 반전: </label>
                <input type="checkbox" name="is_flip_x" ${template_meta.is_flip_x ? 'checked' : ''} />
                <label>텍스트만 복사: </label>
                <input type="checkbox" name="text_only" ${template_meta.text_only ? 'checked' : ''} />
                <label>dpi: </label>
                <input type="number" name="dpi" value="${template_meta.dpi}" />
                <label>line_width: </label>
                <input type="number" name="line_width" value="${template_meta.line_width}" />
                <label>stroke_color: </label>
                <br />
                <input type="text" name="stroke_color" value="${template_meta.stroke_color}" />
                <label>background_color: </label>
                <input type="text" name="bg_color" value="${template_meta.bg_color}" />
                <label>image type(ex image/png, image/jpeg): </label>
                <input type="text" name="mimetype" value="${template_meta.mimetype}" />
                <div class="file_wrapper" style="display:none;">
                  <label>mask_url: </label>
                  <input type="text" name="mask_url" value="${template_meta.mask_url}" />
                  <input type="file" />
                </div>
                <div class="file_wrapper">
                  <label>상단 레이어: </label>
                  <input type="text" name="top_layer_url" value="${template_meta.top_layer_url}" />
                  <input type="file" />
                </div>
                <div class="file_wrapper">
                  <label>검흰 상단 레이어: </label>
                  <input
                    type="text"
                    name="black_and_white_outline_layer_url"
                    value="${template_meta.black_and_white_outline_layer_url}"
                  />
                  <input type="file" />
                </div>
                <div class="file_wrapper">
                  <label>after_downloaded_file_url: </label>
                  <input
                    type="text"
                    name="after_downloaded_file_url"
                    value="${template_meta.after_downloaded_file_url}"
                  />
                  <input type="file" />
                </div>
              </div>
            </form>
            <form class="obj_wrapper" data-obj_name="size">
              <label for="">TEMPLATE SIZE</label>
              <div class="wrapper">
                <label
                  >#width (<span class="width_px"
                    >${(template_meta.size.width / template_meta.dpi) * 2.54}</span
                  >
                  px) :
                </label>
                <input type="number" name="width" value="${template_meta.size.width}" />
                <label
                  >#heigh (<span class="height_px">
                    ${(template_meta.size.height / template_meta.dpi) * 2.54}</span
                  >
                  px) t:
                </label>
                <input type="number" name="height" value="${template_meta.size.height}" />
              </div>
            </form>
            <form class="obj_wrapper" data-obj_name="qr_data">
              <label for="">QR DATA</label>
              <div class="wrapper">
                <label>#x: </label>
                <input type="number" name="x" value="${template_meta.qr_data?.x ?? ''}" />
                <label>#y: </label>
                <input type="number" name="y" value="${template_meta.qr_data?.y ?? ''}" />
                <label>#width: </label>
                <input type="number" name="width" value="${template_meta.qr_data?.width ?? ''}" />
                <label>#height: </label>
                <input type="number" name="height" value="${template_meta.qr_data?.height ?? ''}" />
                <label>#margin: </label>
                <input type="number" name="margin" value="${template_meta.qr_data?.margin ?? ''}" />
              </div>
            </form>
            <form class="obj_wrapper" data-obj_name="text">
              <label for="">TEXT</label>
              <p>주문서번호 : projection_id</p>
              <p>상품번호 : up_id</p>
              <p>상품상세번호 : up_c_id</p>
              <p>제품 이름 : base_product_name</p>
              <p>색상 이름 : base_product_color_name</p>
              <p>옵션 이름 : 옵션그룹명(#op_꼭꼬핀_#op)</p>
              <p>개수 : quantity</p>
              <div class="wrapper">
                <label for="">x: </label>
                <input
                  type="number"
                  name="left"
                  value="${Number.isNaN(template_meta.text.left) ? -1 : template_meta.text.left}"
                />
                <label for="">y: </label>
                <input
                  type="number"
                  name="top"
                  value="${Number.isNaN(template_meta.text.top) ? -1 : template_meta.text.top}"
                />
                <label for="">height: </label>
                <input type="number" name="height" value="${template_meta.text.height}" />
                <label for="">info_separate: </label>
                <input
                  type="checkbox"
                  name="is_separate"
                  ${template_meta.text.is_separate ? 'checked' : ''}
                />
                <label>좌우 반전: </label>
                <input type="checkbox" name="is_flip_x" ${template_meta.text.is_flip_x ? 'checked' : ''} />
                <div>
                  <label for="">file_name: </label>
                  <input
                    type="text"
                    name="file_name"
                    value="${template_meta.text.file_name}"
                    style="width:600px;"
                  />
                </div>
                <div>
                  <label for="">copy_text: </label>
                  <input
                    type="text"
                    name="copy_text"
                    value="${template_meta.text.copy_text}"
                    style="width:600px;"
                  />
                </div>
                <div>
                  <label for=""
                    >이미지 안에 정보 넣기(ex
                    ["#projection_id/up_id/base_product_name/base_product_color_name/quantityea"]):
                  </label>
                  <textarea name="info_arr_in_image" data-type="json" id="" cols="100" rows="1">
${JSON.stringify(template_meta.text.info_arr_in_image)}</textarea
                  >
                </div>
              </div>
            </form>
            <div>
              ${go(
                template_meta.items,
                strMap((item) => {
                  return html`
                    <form class="obj_wrapper" data-obj_name="item">
                      <label for="">ITEM ${item.bpf_name}</label>
                      <div class="wrapper">
                        <input type="text" name="bpf_name" value="${item.bpf_name}" hidden />
                        <input
                          type="number"
                          name="base_product_face_id"
                          value="${item.base_product_face_id}"
                          hidden
                        />
                        <div>
                          <label for="">#x:</label>
                          <input type="number" name="left" value="${item.left}" />
                          <label for="">#y:</label>
                          <input type="number" name="top" value="${item.top}" />
                          <label for="">#width:</label>
                          <input type="number" name="width" value="${item.width}" />
                          <label for="">#height:</label>
                          <input type="number" name="height" value="${item.height}" />
                        </div>
                        <div style="margin-bottom:8px;" for="">프린트 영역</div>
                        <div>
                          <label for="">top:</label>
                          <input type="number" name="print_margin_top" value="${item.print_margin_top}" />
                          <label for="">right:</label>
                          <input type="number" name="print_margin_right" value="${item.print_margin_right}" />
                          <label for="">bottom:</label>
                          <input
                            type="number"
                            name="print_margin_bottom"
                            value="${item.print_margin_bottom}"
                          />
                          <label for="">left:</label>
                          <input type="number" name="print_margin_left" value="${item.print_margin_left}" />
                        </div>
                        <div style="margin-bottom:8px;" for="">제품 영역</div>
                        <div>
                          <label for="">top:</label>
                          <input type="number" name="product_margin_top" value="${item.product_margin_top}" />
                          <label for="">right:</label>
                          <input
                            type="number"
                            name="product_margin_right"
                            value="${item.product_margin_right}"
                          />
                          <label for="">bottom:</label>
                          <input
                            type="number"
                            name="product_margin_bottom"
                            value="${item.product_margin_bottom}"
                          />
                          <label for="">left:</label>
                          <input
                            type="number"
                            name="product_margin_left"
                            value="${item.product_margin_left}"
                          />
                        </div>
                        <div style="margin-bottom:8px;" for="">안전 영역</div>
                        <div>
                          <label for="">top:</label>
                          <input type="number" name="safety_margin_top" value="${item.safety_margin_top}" />
                          <label for="">right:</label>
                          <input
                            type="number"
                            name="safety_margin_right"
                            value="${item.safety_margin_right}"
                          />
                          <label for="">bottom:</label>
                          <input
                            type="number"
                            name="safety_margin_bottom"
                            value="${item.safety_margin_bottom}"
                          />
                          <label for="">left:</label>
                          <input type="number" name="safety_margin_left" value="${item.safety_margin_left}" />
                        </div>
                        <div>
                          <label for="">좌우 반전</label>
                          <input
                            type="checkbox"
                            name="is_horizontal_flip"
                            ${item.is_horizontal_flip ? 'checked' : ''}
                          />
                        </div>
                        <div class="file_wrapper">
                          <label>url(칼선 이미지, 합성에 도움 주는것 포함): </label>
                          <input type="text" name="print_item_url" value="${item.print_item_url}" />
                          <input type="file" />
                        </div>
                        <div class="file_wrapper">
                          <label>인쇄용 파일에 적용할 마스크 이미지 url: <span class="size_px"></span><span class="size_cm"></label>
                          <input type="text" name="mask_url" value="${item.mask_url}" />
                          <input type="file" />
                        </div>
                        <button type="button" class="make_auto_print_item_url" style="margin-bottom:16px;">
                          자동 마스크 템플릿 이미지 만들기
                        </button>
                        <div class="file_wrapper">
                          <label>상위 레이어 ( ITEM 크기와 다를 수 있습니다. - 스트로크 일 경우 ): <span class="size_px"></span><span class="size_cm"></span></label>
                          <input type="text" name="top_layer_url" value="${item.top_layer_url}" />
                          <input type="file" />
                        </div>
                      </div>
                    </form>
                  `;
                }),
              )}
            </div>
          </div>
        `,
      )(template_meta)}
    </div>
    <div class="option">
      <button type="button" class="template_done">템플릿 완성</button>
      <button type="button" class="save">저장</button>
    </div>
  `;
};
