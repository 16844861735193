import { add, filter, flat, go, identity, map, mapL, pluck, rangeL, reduce, sel, sortBy } from 'fxjs/es';
import { ffd, forceFitSize, splitObject } from './algorithm.js';

const selProductsFromProjection = sel('_.ups');
const selProductCount = sel('quantity');

export const getProductCountFromProjection = (prj, product_filter = identity) =>
  go(prj, selProductsFromProjection, filter(product_filter), (ps) =>
    reduce((acc, p) => acc + selProductCount(p), 0, ps),
  );

export const isLargeForArrange = (prj, product_filter, has_cover, max_count) =>
  getProductCountFromProjection(prj, product_filter) + (has_cover ? 1 : 0) > max_count;

export const convertPrjToPrintItems = (prjs, has_cover) =>
  go(
    prjs,
    map((prj) => {
      const cover = { projection: prj, is_cover: true };
      const products = go(
        prj.print_products,
        map((pp) =>
          go(
            rangeL(pp.print_count),
            mapL(() => pp),
          ),
        ),
        flat,
      );

      return flat([has_cover ? [cover] : [], products]);
    }),
    flat,
  );

export const addPrintCounts =
  ({ product_filter, getUpMutiplier, max_pdf_cutting_count, has_cover }) =>
  (prj) => {
    const print_products = go(
      selProductsFromProjection(prj),
      filter(product_filter),
      map((up) => ({
        ...up,
        print_count: selProductCount(up) * getUpMutiplier(up),
      })),
    );

    const total_post_count = reduce(add, pluck('print_count', print_products));
    const total_print_count =
      total_post_count + (has_cover ? Math.ceil(total_post_count / max_pdf_cutting_count) : 0);

    return { ...prj, total_print_count, print_products };
  };

export const getPrintArrangement = (
  _projections,
  {
    max_pdf_paper,
    max_print_per_paper,
    has_cover,
    is_large,
    product_filter = identity,
    itemsToPages,
    getUpMutiplier = () => 1,
  },
) => {
  const max_pdf_cutting_count = max_pdf_paper * max_print_per_paper;

  const projections = go(
    _projections,
    map(addPrintCounts({ product_filter, getUpMutiplier, max_pdf_cutting_count, has_cover })),
    sortBy((p1, p2) => p2.total_print_count - p1.total_print_count),
  );

  const splitProducts = (prj, rest_size) => {
    const product_max = rest_size - (has_cover ? 1 : 0);
    const [print_products, rest_print_products] = forceFitSize(
      prj.print_products,
      product_max,
      sel('print_count'),
      (product, size) => [
        { ...product, print_count: size },
        { ...product, print_count: product.print_count - size },
      ],
      { bin_count: 1 },
    );

    return [
      { ...prj, print_products, total_print_count: rest_size },
      {
        ...prj,
        print_products: rest_print_products,
        total_print_count: prj.total_print_count - rest_size,
      },
    ];
  };

  const prj_cutting_groups = is_large
    ? go(
        projections,
        map((prj) => splitObject(prj, max_pdf_cutting_count, sel('total_print_count'), splitProducts)),
        flat,
        map((part_prj) => [part_prj]),
      )
    : ffd(projections, max_pdf_cutting_count, sel('total_print_count'));

  return go(
    prj_cutting_groups, // == pdf
    map((prjs) => convertPrjToPrintItems(prjs, has_cover)),
    map((print_items) => itemsToPages(print_items, { max_print_per_paper })),
  );
};
