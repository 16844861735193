import axios from 'axios';
import Swal from 'sweetalert2';

import { zplWriteGenerator } from '../../../Stock/Labeling/F/Function/events.js';
import { LabelPage, StockLabelFormat } from '../../../Stock/zpl_module/zpl_class.js';
import { each, go, map, head, isNil, reverse } from 'fxjs/es';
import { writeToZPLdata } from '../../../Stock/zpl_module/zpl_command_utils.js';
import ProgressBar from 'progressbar.js';
import { $qs, $setCss } from 'fxdom/es';
import { convertISODateToMMDD } from '../../../Stock/Labeling/F/Function/labeling.js';
import { label_aztec_code_src } from '../S/label_aztec_code_src.js';
import { DfWaybillF } from '../../../Waybill/F/Function/module/DfWaybillF.js';
import { convertShippingTypeToLabel } from '../S/constants.js';

const collabo_type_names = {
  line: '라인',
  creator: '샵',
};
const parseCollaboType = (type_str) => {
  const type_kr = collabo_type_names[type_str];
  return type_kr || '마플';
};

export const formattingProjectionLabels = ({
  projection_id,
  store_name,
  scan_info,
  total_quantity,
  order_at,
  due_at,
  collabo_type,
  shipping_type,
  is_cancel_requested,
  is_repress,
  is_urgent,
  is_internal_urgent,
}) => {
  return {
    projection_id,
    store_name,
    scan_info,
    total_quantity,
    order_at,
    due_at,
    collabo_type,
    shipping_type,
    is_cancel_requested,
    is_repress,
    is_urgent,
    is_internal_urgent,
  };
};

export const getProjectionFormattedLabelData = (projection_id, data) =>
  formattingProjectionLabels({
    projection_id,
    store_name: data._?.store?.name,
    scan_info: label_aztec_code_src({ projection_id }),
    total_quantity: data.quantity,
    order_at: moment(data.ordered_at).format('YY.MM.DD HH:mm'),
    due_at: moment(data.due_at).format('YY.MM.DD HH:mm'),
    collabo_type: data.is_shopify
      ? '쇼피'
      : data.merged_type === 'sample'
      ? '#S'
      : parseCollaboType(data.collabo_type),
    shipping_type:
      data.type === 'merged_parent'
        ? '제작'
        : convertShippingTypeToLabel[
            `${data?._?.shippings ? head(data._.shippings).type : data.shipping_type}`
          ],
    is_cancel_requested: data.is_cancel_req || data.is_canceled,
    is_repress: data.is_repress,
    is_urgent: data.important ?? false,
    is_internal_urgent: data.internal_important ?? false,
  });

export const getProjectionLabelData = async (projection_id) => {
  /*** 주문 라벨 데이터 수집 내용
   * 1. projection_id : 주문 번호
   * 2. scan_info: 아즈텍 코드에 들어갈 컨텐츠 내용
   * 3. total_quantity: 주문 총 수량
   * 4. order_at: 주문 일자
   * 5. due_at: 출고 일자
   * 6. collabo_type: 주문 경로
   * 7. shipping_type: 배송 방식
   * 8. is_cancel_requested: 취소 요청 여부
   * 9. is_repress: 재제작 여부
   * 10. is_urgent: 긴급 여부 (고객 요청)
   * 11. is_internal_urgent: 내부 긴급 여부 (출고 기한)
   * */
  $.don_loader_start();
  projection_id = Number(projection_id);
  try {
    const { data } = await axios({
      url: '/@api/projection/parentLabelData',
      method: 'get',
      params: { projection_id },
    });
    let label_data;

    if (data.type === 'merged_parent') {
      $.don_loader_end();
      await Swal.fire({
        title: 'Marpple Shop<br>[ 배송용 / 제작용 ]<br>출력 라벨 선택',
        icon: 'warning',
        text: '어떤 라벨을 출력하시겠어요?',
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        denyButtonColor: '#1aab18',
        cancelButtonColor: '#d33',
        confirmButtonText: '배송용',
        denyButtonText: `제작용`,
        cancelButtonText: '취소',
        showLoaderOnConfirm: true,
        //confirm(배송용 선택)일 때 children projections query 요청
        preConfirm: async () => {
          return axios({
            url: '/@api/projection/childsLabelData',
            method: 'get',
            params: { projection_id },
          })
            .then((response) => {
              if (response.status !== 200) {
                throw new Error(response.statusText);
              } else {
                return response.data;
              }
            })
            .catch((error) => {
              Swal.showValidationMessage(`배송용 주문정보 조회 에러 발생.<br>${error}`);
            });
        },
      }).then((result) => {
        if (result.isDenied) {
          //제작용 라벨 (merged_parent) => 조회된 부모 주문서 정보 return
          label_data = [getProjectionFormattedLabelData(projection_id, data)];
        } else if (result.isConfirmed) {
          //배송용 라벨 (merged_parent 에 붙어있는 children 정보 return)
          label_data = go(
            result.value,
            map((data) => getProjectionFormattedLabelData(data.id, data)),
            reverse,
          );
        }
      });
      return label_data;
    } else {
      return [getProjectionFormattedLabelData(projection_id, data)];
    }
  } catch (e) {
    $.don_loader_end();
    console.error(e);
    $.alert(`라벨 데이터를 획득하는데 실패했습니다. 개발자 문의 주세요.<br>Error: ${e}`);
  }
};

export const printProjectionLabel = async (label_data) => {
  if (isNil(label_data) || !label_data?.length) return;
  try {
    $.don_loader_start();
    const device = await DfWaybillF.prepareLabelPrinter({
      setting_fn: DfWaybillF.printer_config_fn.projection_label,
      allowed_devices: ['ZD420', 'ZD421'],
    });
    const projection_label_format_filename = 'E:normal_label_format.ZPL';
    if (!device) return;
    const format = new StockLabelFormat(projection_label_format_filename, 203, 1, 0, false, 50);
    format.setDefaultFont('0', 20);
    go(
      PRJ_LABEL_FORMAT_DATA,
      each((d) => {
        const field = format.makeField(d.title, d.type, d.static_data, d.mutator, d.tree_name);
        const pos = d.position;
        const font = d.font;
        if (d.field_box) {
          const fb = d.field_box;
          field.setFieldBox(fb.width, fb.max_lines, fb.line_gap, fb.text_just, fb.indent);
        } else if (d.aztec_code) {
          field.setAztecCode(d.aztec_code.magnification);
        } else if (d.image) {
          const { mag, name } = d.image;
          field.setImage({ x: pos.x, y: pos.y, mag, name });
        }
        format.pushFieldData(field, pos.x, pos.y, font?.name, font?.orient, font?.height, font?.width);
      }),
    );

    const label_pages_zpl_str = go(
      label_data,
      map((label) => {
        const page = new LabelPage(format, label, null);
        // page.printFieldBox();
        const letter_ctn = label.projection_id.toString().length;
        const letter_width = 3.9;
        const right_end_pos = 32;

        // 줄 긋기
        go(
          label.is_cancel_requested ? [4, 8, 11.5] : [4, 11.5],
          each((y_pos) => {
            page.addGraphicBox(
              right_end_pos - letter_width * letter_ctn,
              y_pos,
              letter_width * letter_ctn,
              0.3,
              3,
              'B',
              0,
            );
          }),
        );

        if (label.is_urgent) {
          //긴급 표시 => 별
          page.addStarMark(1, 5, 30);
        } else {
          //내부 긴급 표시 => 비어있는 별
          if (label.is_internal_urgent) {
            page.addBlankStarMark(1, 5, 30);
          }
        }
        // 재제작 => 화살표 표시
        if (label.is_repress) {
          page.addRepressMark(1, 7.5, 40);
        }

        const zpl_str = page.toZPLStr();
        return zpl_str;
      }),
    );
    const format_zpl = format.toDownloadFormat();
    await writeToZPLdata(device, format_zpl);

    const id_progress_bar = 'print_progress_bar';
    let progress_bar_el = $qs(`#${id_progress_bar}`, document.body);
    if (progress_bar_el) {
      progress_bar_el.remove();
    }
    progress_bar_el = document.createElement('div');
    progress_bar_el.id = id_progress_bar;
    document.body.appendChild(progress_bar_el);

    const size = { width: 100, height: 100 };
    $setCss(
      {
        position: 'fixed',
        width: `${size.width}px`,
        height: `${size.height}px`,
        top: `${(window.innerHeight - size.height) / 2}`,
        left: `${(window.innerWidth - size.width) / 2}`,
      },
      progress_bar_el,
    );

    const bar = new ProgressBar.Circle('#print_progress_bar', {
      color: '#222',
      // This has to be the same size as the maximum width to
      // prevent clipping
      strokeWidth: 10,
      trailWidth: 1,
      easing: 'easeInOut',
      duration: 700,
      text: {
        autoStyleContainer: false,
      },
      from: { color: '#555', width: 7 },
      to: { color: '#222', width: 10 },
      // Set default step function for all animate calls
      step: function (state, circle) {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);
        const value = Math.round(circle.value() * 100);
        if (value === 0) {
          circle.setText('');
        } else {
          circle.setText(value);
        }
      },
    });

    bar.text.style.fontSize = '2rem';
    bar._container.style.zIndex = Number.MAX_SAFE_INTEGER;

    $.don_loader_end();

    for await (const value of zplWriteGenerator(device, label_pages_zpl_str)) {
      bar.animate(value);
      if (value === 1) {
        window.setTimeout(() => {
          bar.destroy();
          progress_bar_el.remove();
          Swal.fire({
            position: 'center',
            width: 300,
            // target: 'document',
            icon: 'success',
            backdrop: false,
            title: `<span style="font-size:18px;">라벨 출력 완료</span>`,
            showConfirmButton: false,
            timer: 1000,
          });
        }, 1000);
      }
    }
  } catch (e) {
    $.don_loader_end();
    await Swal.fire({
      position: 'center',
      icon: 'error',
      width: 500,
      backdrop: false,
      title: '라벨프린터 오류 발생',
      imageUrl:
        '//s3.marpple.co/files/u_1187078/2021/12/original/f4535d5dce29afd5e3ada6ad2b087941171877161.png',
      imageWidth: 200,
      html: `연결 혹은 상태를 점검 해주세요.<br>${e}`,
      showConfirmButton: true,
    });
  }
};

const PRJ_LABEL_FORMAT_DATA = [
  {
    title: 'branding',
    type: 'static',
    static_data: ['Besties For Creator'],
    field_box: {
      width: 40,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: { x: 10, y: 1 },
    font: {
      name: 'J',
      orient: 'N',
      height: 2,
      width: null,
    },
  },
  {
    title: 'projection_id',
    type: 'variable',
    field_box: {
      width: 28,
      max_lines: 1,
      line_gap: 0,
      text_just: 'R',
      indent: 0,
    },
    position: { x: 4, y: 5 },
    font: {
      name: '0',
      orient: 'N',
      height: 8,
      width: null,
    },
  },
  {
    // MARPPLE LOGO
    title: 'marpple_logo',
    type: 'static',
    position: { x: 7, y: 0.65 },
    image: {
      name: 'marpple',
    },
  },
  {
    //AZTEC CODE
    title: 'scan_info',
    type: 'variable',
    aztec_code: { magnification: 7 },
    position: { x: 34, y: 3 },
  },
  {
    title: 'due_at',
    type: 'variable',
    static_data: null,
    mutator: convertISODateToMMDD,
    tree_name: null,
    field_box: {
      width: 11,
      max_lines: 1,
      line_gap: 0,
      text_just: 'C',
      indent: 0,
    },
    position: {
      x: 1,
      y: 12.5,
    },
    font: {
      name: '0',
      orient: 'N',
      height: 4,
      width: null,
    },
  },
  {
    title: 'shipping_type',
    type: 'variable',
    static_data: null,
    tree_name: null,
    field_box: {
      width: 10,
      max_lines: 1,
      line_gap: 0,
      text_just: 'C',
      indent: 0,
    },
    position: {
      x: 12,
      y: 12.5,
    },
    font: {
      name: 'J',
      orient: 'N',
      height: 4,
      width: null,
    },
  },
  {
    title: 'collabo_type',
    type: 'variable',
    static_data: null,
    tree_name: null,
    field_box: {
      width: 10,
      max_lines: 1,
      line_gap: 0,
      text_just: 'C',
      indent: 0,
    },
    position: {
      x: 23,
      y: 12.5,
    },
    font: {
      name: 'J',
      orient: 'N',
      height: 4,
      width: null,
    },
  },
  {
    title: 'store_name',
    type: 'variable',
    static_data: null,
    tree_name: null,
    field_box: {
      width: 45,
      max_lines: 1,
      line_gap: 0,
      text_just: 'L',
      indent: 0,
    },
    position: {
      x: 2,
      y: 17.2,
    },
    font: {
      name: 'J',
      orient: 'N',
      height: 2,
      width: null,
    },
  },
];
