export const CJ = 'cj';
// export const KPOST = 'kpost';
export const DOORA = 'doora';
export const DHL = 'dhl';
export const DHL_DIRECT = 'dhl-direct';
export const LOTTELOGI = 'lotte';
export const EMS = 'ems';
export const HANJIN = 'hanjin';
export const CJ_IMG = 'https://www.cjlogistics.com/static/pc/global/template/images/logo/logo_footer_ko.png';
export const DOORA_IMG = 'http://web.doora.co.kr/img/logo/doora_200x95.png';
export const DHL_IMG = 'https://www.dhl.com/content/dam/dhl/global/core/images/logos/dhl-logo.svg';
export const EMS_IMG =
  '//s3.marpple.co/files/u_29089/2020/5/150/18665226d465aa3267cb7f7db5ed8d71aafe14395fa88b0.png';
// export const KPOST_IMG = 'https://www.epost.go.kr/np2assets/images/e1/logo.gif';

export const HANJIN_IMG =
  'https://www.hanjin.com/attach/IMAGE/TopDesignMgr/LogoImgUpload/2020/7/QelEmKDAO0XUb82H.PNG';

export const SHIP_IMG = {
  [CJ]: CJ_IMG,
  [DOORA]: DOORA_IMG,
  [DHL]: DHL_IMG,
  [DHL_DIRECT]: DHL_IMG,
  [EMS]: EMS_IMG,
  [HANJIN]: HANJIN_IMG,
  // [KPOST]: KPOST_IMG,
};
