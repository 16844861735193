import axios from 'axios';
import { go, html, pipe, sel } from 'fxjs/es';

const path = $.hasClass($1('html'), 'line') ? '/line' : '';
export const defnPromotionCodeTab = () => ({
  tab_name: 'df.promotion_code',
  appending: pipe(
    $.on2('click', '#insert_promotion', () =>
      $.frame.open(
        {
          frame_name: 'df.promotion_code.upsert',
        },
        {
          page_name: 'df.promotion_code.upsert',
        },
      ),
    ),
    $.on2('click', 'td.update button.update_promotion', (e) =>
      $.frame.open(
        {
          frame_name: 'df.promotion_code.upsert',
        },
        {
          page_name: 'df.promotion_code.upsert',
          tabs: [
            {
              tab_name: 'df.promotion_code.upsert',
              data_func: function () {
                return box.sel(e.currentTarget);
              },
            },
          ],
        },
      ),
    ),
    $.on2('click', 'td.update button.is_public', (e) =>
      go(
        $.post(`/@api/promotion_code/is_public${path}`, {
          id: box.sel(e.currentTarget).id,
        }),
        () => location.reload(),
      ),
    ),
  ),
});

export const defnPromotionCodeUpsertTab = () => ({
  tab_name: 'df.promotion_code.upsert',
  template: (promotion_code = {}) => html`
    <div id="upsert_promotion_code">
      <h2>프로모션 코드 ${promotion_code.id ? `수정 (ID: ${promotion_code.id})` : '추가'}</h2>
      <div class="form">
        <input type="hidden" name="id" value="${promotion_code.id}" />
        <div class="code_name section">
          <label>
            <span>코드명(영어 대문자)</span>
            <input type="text" name="name" value="${promotion_code.name}" />
          </label>
        </div>
        <div class="at section">
          <div>접속한 나라 및 도시 시간 기준</div>
          <label>
            <input
              type="date"
              name="start_at"
              value="${promotion_code.start_at
                ? moment(promotion_code.start_at).format('YYYY-MM-DD')
                : void 0}"
            />
            <span>(0시)</span>
          </label>
          <span style="margin-right: 8px;"> ~ </span>
          <label>
            <input
              type="date"
              name="end_at"
              value="${promotion_code.end_at ? moment(promotion_code.end_at).format('YYYY-MM-DD') : void 0}"
            />
            <span>(24시)</span>
          </label>
        </div>
        <div class="price_or_percent section">
          <div class="info"></div>
          <label>
            <input
              type="radio"
              name="type"
              value="price"
              ${promotion_code.type == 'price' ? 'checked' : ''}
            />
            <span>금액할인</span>
          </label>
          <label>
            <input
              type="radio"
              name="type"
              value="percent"
              ${promotion_code.type == 'percent' ? 'checked' : ''}
            />
            <span>할인율</span>
          </label>
          <div class="selected_condition">
            <div class="info">
              <div>비어있거나 값이 0인 화폐는 해당 언어의 프로모션이 적용되지 않습니다.</div>
            </div>
            <div class="price_cond cond ${promotion_code.type == 'price' ? 'selected' : ''}">
              <label>
                <span>₩</span>
                <input type="number" name="price" value="${promotion_code.price || 0}" />
              </label>
              <label>
                <span>$</span>
                <input type="number" name="price_en" value="${parseFloat(promotion_code.price_en) || 0}" />
              </label>
              <label>
                <span>¥</span>
                <input type="number" name="price_jp" value="${promotion_code.price_jp || 0}" />
              </label>
            </div>
            <div class="percent_cond cond ${promotion_code.type == 'percent' ? 'selected' : ''}">
              <label>
                <span>₩</span>
                <input type="number" max="100" name="percent" value="${promotion_code.percent || 0}" />
                <span>%</span>
              </label>
              <label>
                <span>$</span>
                <input type="number" max="100" name="percent_en" value="${promotion_code.percent_en || 0}" />
                <span>%</span>
              </label>
              <label>
                <span>¥</span>
                <input type="number" max="100" name="percent_jp" value="${promotion_code.percent_jp || 0}" />
                <span>%</span>
              </label>
            </div>
          </div>
        </div>
        <div class="min_price section">
          <div class="info">최소 주문 금액</div>
          <div>
            <label>
              <span>₩</span>
              <input type="number" name="min_price" value="${promotion_code.min_price || 0}" />
            </label>
            <label>
              <span>$</span>
              <input
                type="number"
                name="min_price_en"
                value="${parseFloat(promotion_code.min_price_en) || 0}"
              />
            </label>
            <label>
              <span>¥</span>
              <input type="number" name="min_price_jp" value="${promotion_code.min_price_jp || 0}" />
            </label>
          </div>
        </div>
        <div class="count_per_user section">
          <label>
            <input
              type="checkbox"
              name="per_or_not"
              value="max"
              ${promotion_code.count_per_user ? 'checked' : ''}
            />
            <span>회원 1명당 사용할 수 있는 수(설정하지 않으면 무제한입니다.)</span>
          </label>
          <div class="max_cond cond${promotion_code.count_per_user ? ' selected' : ''}">
            <label>
              <span>최대 사용 수(0은 무제한입니다)</span>
              <input type="number" name="count_per_user" value="${promotion_code.count_per_user || 0}" />
            </label>
          </div>
        </div>
        <div class="max_count section">
          <label>
            <input
              type="checkbox"
              name="max_or_at"
              value="max"
              ${promotion_code.max_count ? 'checked' : ''}
            />
            <span>총 사용량 설정(설정하지 않으면 무제한입니다)</span>
          </label>
          <div class="max_cond cond${promotion_code.max_count ? ' selected' : ''}">
            <label>
              <span>최대 사용 수(0은 무제한입니다)</span>
              <input type="number" name="max_count" value="${promotion_code.max_count || 0}" />
            </label>
          </div>
        </div>
        ${!path
          ? html`<div class="store_id section">
              <label>
                <input
                  type="checkbox"
                  name="store_or_not"
                  value="store"
                  ${promotion_code.store_id ? 'checked' : ''}
                />
                <span>마플샵 스토어 전용</span>
              </label>
              <div class="store_cond cond${promotion_code.store_id ? ' selected' : ''}">
                <div>
                  <label>
                    <span>스토어 주소를 복사해서 붙여주세요.</span>
                    <input
                      type="text"
                      name="domain_name"
                      placeholder="ex) https://marpple.shop/kr/testseller"
                      value="${promotion_code?._?.store?.domain_name
                        ? `https://marpple.shop/${promotion_code?._?.store?.domain_name}`
                        : ''}"
                    />
                  </label>
                </div>
                <div>
                  <label>
                    <span>샘플 전용</span>
                    <input type="checkbox" name="is_sample" ${promotion_code.is_sample ? 'checked' : ''} />
                  </label>
                </div>
                <div>
                  <label>
                    <span>마플샵 정산 제외</span>
                    <input
                      type="checkbox"
                      name="except_adjustment"
                      ${promotion_code.except_adjustment ? 'checked' : ''}
                      ${!box.sel('is_user->_->policies->mp_shop_sgn_policy') ? 'disabled' : ''}
                    />
                  </label>
                </div>
              </div>
            </div>`
          : ''}
        <div class="description section">
          <textarea name="description" placeholder="프로모션에 대한 국문 설명">
${promotion_code.description || void 0}</textarea
          >
        </div>
        <div class="description section">
          <textarea name="description_en" placeholder="프로모션에 대한 영문 설명">
${promotion_code.description_en || void 0}</textarea
          >
        </div>
        <div class="description section">
          <textarea name="description_jp" placeholder="프로모션에 대한 일문 설명">
${promotion_code.description_jp || void 0}</textarea
          >
        </div>
        <div class="buttons section">
          <button class="save" type="button">저장</button>
          <button class="close" type="button" onclick="$.frame.close();">닫기</button>
        </div>
      </div>
    </div>
  `,
  appended: pipe(
    $.on('change', 'input[name="domain_name"]', async (e) => {
      try {
        if (
          (
            await axios.get('/@api/promotion_code/store', {
              params: { domain_name: e.currentTarget.value },
            })
          ).data
        )
          return;
        await $.alert('주소를 확인해 주세요.');
        e.currentTarget.value = '';
      } catch (e) {}
    }),

    $.on('change', 'input[type="radio"]', function (e) {
      go(
        e,
        sel('currentTarget'),
        $.closest('.section'),
        $.find1('.selected_condition .cond.' + $.val(e.currentTarget) + '_cond'),
        $.add_class('selected'),
        $.siblings,
        $.remove_class('selected'),
      );
    }),

    $.on('change', 'input[type="checkbox"]', function (e) {
      go(
        e,
        sel('currentTarget'),
        $.closest('.section'),
        $.find1('.cond.' + $.val(e.currentTarget) + '_cond'),
        (e.currentTarget.checked ? $.add_class : $.remove_class)('selected'),
      );
    }),

    $.on2('click', '.buttons button.save', async function (e) {
      const el_form = $.find1(e.delegateTarget, '.form');
      const id = $.val($.find1(el_form, 'input[name="id"]')) || void 0;
      const name = $.val($.find1(el_form, '.code_name input[name="name"]')) || void 0;
      const start_at = $.val($.find1(el_form, 'input[name="start_at"]') || void 0);
      const end_at = $.val($.find1(el_form, 'input[name="end_at"]')) || void 0;
      const type = $.val($.find1(el_form, 'input[name="type"]:checked')) || void 0;
      const max_count = $.val($.find1(el_form, '.cond.selected input[name="max_count"]')) || void 0;
      const description = $.val($.find1(el_form, 'textarea[name="description"]')) || void 0;
      const description_en = $.val($.find1(el_form, 'textarea[name="description_en"]')) || void 0;
      const description_jp = $.val($.find1(el_form, 'textarea[name="description_jp"]')) || void 0;
      const min_price = $.val($.find1(el_form, 'input[name="min_price"]')) || void 0;
      const min_price_en = $.val($.find1(el_form, 'input[name="min_price_en"]')) || void 0;
      const min_price_jp = $.val($.find1(el_form, 'input[name="min_price_jp"]')) || void 0;
      const count_per_user = $.val($.find1(el_form, '.cond.selected input[name="count_per_user"]')) || void 0;
      const domain_name = $.val($.find1(el_form, '.cond.selected input[name="domain_name"]')) || void 0;
      const is_sample = !!$.find1(el_form, '.cond.selected input[name="is_sample"]')?.checked;
      const except_adjustment = !!$.find1(el_form, '.cond.selected input[name="except_adjustment"]')?.checked;
      const data = {
        type,
        [type]: $.val($.find1(el_form, `input[name="${type}"]`)) || void 0,
        [type + '_en']: $.val($.find1(el_form, `input[name="${type + '_en'}"]`)) || void 0,
        [type + '_jp']: $.val($.find1(el_form, `input[name="${type + '_jp'}"]`)) || void 0,
        id,
        name,
        start_at,
        end_at,
        count_per_user,
        description,
        description_en,
        description_jp,
        min_price,
        min_price_en,
        min_price_jp,
        domain_name,
        except_adjustment,
        max_count: max_count || void 0,
        is_sample,
      };
      if (!name || name.length < 4) return $.alert('코드명을 작성해 주세요. (최소 4글자 이상)');
      if (!start_at || !end_at) return $.alert('시작날짜와 마지막날짜는 반드시 선택해 주세요.');
      if (!data[type] && !data[type + '_en'] && !data[type + '_jp'])
        return $.alert('₩, $, ¥ 모두 값이 비어있습니다.');
      return go(
        void 0,
        () => {
          const _langs = [];
          if (data[type]) _langs.push('국');
          if (data[type + '_en']) _langs.push('영');
          if (data[type + '_jp']) _langs.push('일');
          return $.confirm(`${_langs.join('/')}문 프로모션을 진행하시겠습니까?`);
        },
        (r) => {
          if (r) return go($.post(`/@api/promotion_code/save${path}`, data), () => location.reload());
        },
      );
    }),
  ),
});
