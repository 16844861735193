import {
  compact,
  each,
  every,
  extend,
  find,
  flatMap,
  go,
  identity,
  map,
  mapObject,
  object,
  sel,
  split,
  tap,
} from 'fxjs/es';
import {
  $addClass,
  $appendTo,
  $closest,
  $delegate,
  $el,
  $find,
  $findAll,
  $qs,
  $remove,
  $removeClass,
  $setVal,
  $trigger,
  $val,
} from 'fxdom/es';
import { makeAdjustHtml } from '../../S/Tmpl/fn.js';
import {
  getBaseProductFacesInMaker,
  getBaseProductInMaker,
  getBaseProductSizesInMaker,
  getCurrentBpfInMaker,
  getCurrentBpsId,
  getFcanvasByBpfId,
  getItsBpf,
  getItsSf,
} from '../../../../Maker/F/getSth.js';
import { getCurrentFcanvas, getCvObj, makeFilterCvObj } from '../../../../Maker/F/Fcanvas/cv_object.js';
import { selectFaceInitA } from '../../../../Maker/F/select_faces.js';
import axios from 'axios';
import { makeMaskImage, ratioCalcHeight, ratioCalcWidth } from './util.js';
import {
  changeProductColorByBps,
  renderFacesByCurrentBpcfsOnlySizeInMakerA,
} from '../../../../Maker/F/mp_maker.js';
import { NewMakerCvObjAlignmentF } from '../../../CvObjAlignment/F/Function/module/NewMakerCvObjAlignmentF.js';
import { makeCanvasByUrl } from '../../../../Canvas/S/util.js';
import { NewMakerPrintResultF } from '../../../PrintResult/F/Function/module/NewMakerPrintResultF.js';
import { plusStrokeWidth } from '../../../../Maker/F/Fcanvas/stroke.js';
import { getTargetSizeByAttr } from '../../../../Maker/F/auto_phonecase_print_img.js';
import { makeCanvasTextured } from '../../../../Maker/F/cylinder.js';
import { makeImageFromUrl } from '../../../../Maker/F/util.js';
import { makeCvPrintItem } from '../../../../Maker/F/CvPrintItem/cv_object.js';
import { VectorEditorConstantS } from '../../../../VectorEditor/S/Constant/module/VectorEditorConstantS.js';
import { NewMakerCvObjActionF } from '../../../CvObjAction/F/Function/module/NewMakerCvObjActionF.js';

export const sizeFaceEditorInit = async () => {
  if ($qs('.size_face_editor')) $remove($qs('.size_face_editor'));
  const base_product = getBaseProductInMaker();
  const base_product_faces = getBaseProductFacesInMaker();
  const base_product_sizes = getBaseProductSizesInMaker();
  const fcanvas = getCurrentFcanvas();
  const sf = getItsSf(fcanvas);
  const current_bpf_id = fcanvas.bpf_id;
  const { print: sf_print, items } = sf;
  const sticker_previews = await (async () => {
    if (!VectorEditorConstantS.PREVIEW_TYPES.includes(box().maker.product_color._.base_product.maker_type))
      return;
    const sticker_previews_wrappers = await $.get('/@api/svg_editor/get_sticker_previews_wrappers', {
      base_product_id: base_product.id,
      maker_type: base_product.maker_type,
    });
    const sticker_previews = sf.sticker_previews;
    go(
      sticker_previews_wrappers,
      each((sticker_previews_wrapper) => {
        go(
          sticker_previews,
          find((sp) => {
            return every((on) =>
              sticker_previews_wrapper.option_names.includes(on.toLowerCase().replace(/\s/g, '')),
            )(sp.option_names);
          }),
          (sp) => {
            sticker_previews_wrapper.url = sp?.url;
          },
        );
      }),
    );
    return sticker_previews_wrappers;
  })();
  const sf_safety = sf.safety || JSON.parse(JSON.stringify(sf_print));
  const item = items && items[0];
  await go(
    makeAdjustHtml({
      base_product_faces,
      item,
      sf_print,
      sf_safety,
      base_product_sizes,
      mobile_maker_size_ratio: base_product.maker_features?.mobile_maker_size_ratio,
      sf,
      sticker_previews,
      current_bpf_id,
    }),
    $el,
    $appendTo($qs('.size_face_editor_wrapper')),
    tap($find('.cv_obj_actions_editor_wrapper'), (cv_obj_actions_editor_wrapper_el) => {
      NewMakerCvObjActionF.editorInit({
        cv_obj_actions_editor_wrapper_el,
        base_product_id: base_product.id,
        base_product_face_id: current_bpf_id,
      });
    }),
    $delegate('click', '.delete_safety', async (e) => {
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      await axios.post('/@api/base_product_faces/update_adjust_area', {
        delete_safety: true,
        base_product_face_id,
        base_product_size_id,
      });
      if (await $.alert('안전영역이 삭제 됐습니다. 새로고침 됩니다.')) location.reload();
    }),
    $delegate('click', '.file_wrapper button', (e) => {
      go(e.currentTarget, $closest('.file_wrapper'), $find('[type="file"]'), (el) => $trigger('click', el));
    }),
    $delegate('click', '.section.select_face button', async (e) => {
      G.mp.maker.editing_canvas(
        box.sel('maker->canvass').indexOf(getFcanvasByBpfId(e.currentTarget.dataset.base_product_face_id)),
      );
      await selectFaceInitA(false, true);
      await sizeFaceEditorInit();
    }),
    $delegate('click', '.print_area_safety_area_setting', (e) => {}),
    $delegate('click', '.print_area_setting', (e) => {}),
    $delegate('click', '.export_area', (e) => {
      const cms = go(
        prompt('칼선 위치에서 몇 cm 안쪽으로 필요합니까?(top,right,bottom,left)'),
        (str) => str.split(','),
        map((a) => a.trim()),
        map(parseFloat),
      );
      if (cms.length !== 4) return $.alert('4개 입력해주세요!');
      const { items, px_per_1cm } = getItsSf(getCurrentFcanvas());
      go(
        items,
        each((item) => {
          const px = item.px;
          const top = px.top + cms[0] * px_per_1cm;
          const left = px.left + cms[3] * px_per_1cm;
          const width = px.width - (cms[1] + cms[3]) * px_per_1cm;
          const height = px.height - (cms[0] + cms[2]) * px_per_1cm;
          const ratio = 2;
          alert(
            `x:${Math.round(left * ratio)}, y:${Math.round(top * ratio)}, width:${Math.round(
              width * ratio,
            )}, height:${Math.round(height * ratio)}, 선 두깨는 최소:${Math.ceil(ratio)}`,
          );
        }),
      );
    }),
    $delegate('click', '.section.etc_function button.apply_to_size_faces', async (e) => {
      const bool = $.confirm('정말 모든 사이즈에 일괄 적용 하시겠습니까? 돌이킬수 없음..');
      if (!bool) return;
      $.don_loader_start();
      const default_sf = getItsSf(getCurrentFcanvas());
      const base_product_face = getItsBpf(getCurrentFcanvas());
      const size_faces = go(
        base_product_face.size_faces,
        map((sf) => {
          const new_sf = JSON.parse(JSON.stringify(default_sf));
          new_sf.base_product_size_id = sf.base_product_size_id;
          return new_sf;
        }),
      );
      await axios.post('/@api/base_product_faces/size_faces_update', {
        size_faces,
        id: base_product_face.id,
      });
      base_product_face.size_faces = size_faces;
      $.don_loader_end();
    }),
    $delegate('click', '.make_cv_safety_area_size .apply', (e) => {
      const v = go(
        e.currentTarget,
        $closest('.make_cv_safety_area_size'),
        $find('input[name="diff_cm"]'),
        $val,
        parseFloat,
      );
      const {
        print: { px, cm },
        px_per_1cm,
      } = getItsSf(getCurrentFcanvas());
      const diff_px = v * px_per_1cm;
      const top = px.top + diff_px;
      const left = px.left + diff_px;
      const width = px.width - diff_px * 2;
      const height = px.height - diff_px * 2;
      const result_cm_width = cm.width - v * 2;
      const result_cm_height = cm.height - v * 2;
      const cv_safety_area_el = $qs('.adjust_area_size[data-cv_type="cv_safety_area"]');
      const px_form_el = $find('form[data-obj="px"]')(cv_safety_area_el);
      px_form_el.top.value = Math.round(top);
      px_form_el.left.value = Math.round(left);
      px_form_el.width.value = Math.round(width);
      px_form_el.height.value = Math.round(height);
      const cm_form_el = $find('form[data-obj="cm"]')(cv_safety_area_el);
      cm_form_el.width.value = result_cm_width;
      cm_form_el.height.value = result_cm_height;
    }),
    $delegate('click', '.section.etc_function button.make_mask', async (e) => {
      $.don_loader_start();
      const mask_target_str = prompt(
        '어떤 대상으로 마스크를 만들겠습니까?(마스크 이미지가 변경됩니다.)',
        'cv_print_item',
      );

      const target_mask = prompt('어떤 마스크를 만들겠습니까?(마스크 이미지가 변경됩니다.)', 'mask2_url');
      const is_opacity = prompt('부분 반투명 마스크를 만들겠습니까?(마스크 이미지가 변경됩니다.)', 'true');
      let mask2_target_no_opacity = null;
      if (is_opacity === 'true') {
        mask2_target_no_opacity = prompt('불투명 마스크2?(마스크 이미지가 변경됩니다.)', 'cv_mask1');
      }
      if (!every(identity)([mask_target_str, target_mask])) return $.don_loader_end();
      await makeMaskImage(mask_target_str, target_mask, is_opacity === 'true', mask2_target_no_opacity);
      $.alert('성공했습니다.');
      $.don_loader_end();
    }),
    $delegate('click', '.section.etc_function button.make_mask2', async (e) => {
      $.don_loader_start();
      const mask_target_str = 'cv_print_item';
      const target_mask = 'mask2_url';
      const is_opacity = 'true';
      const mask2_target_no_opacity = 'cv_mask1';
      if (!every(identity)([mask_target_str, target_mask])) return $.don_loader_end();
      await makeMaskImage(mask_target_str, target_mask, is_opacity === 'true', mask2_target_no_opacity);
      $.alert('성공했습니다.');
      $.don_loader_end();
    }),
    $delegate('click', '.section.etc_function button.change_all_other_print_area', async (e) => {
      if (!(await $.confirm('현재 면의 프린트 영역 상태가 전체에 모두 적용 됩니다.'))) return;
      $.don_loader_start();
      alert('준비중 입니다.');
      $.don_loader_end();
    }),
    $delegate('click', '.section.select_size .change_size', async (e) => {
      if ($.has_class($1('#maker_frame'), 'pc_canvas_change_ing')) return;
      const id = parseInt(e.currentTarget.dataset.base_product_size_id);
      $.add_class($1('#maker_frame'), 'pc_canvas_change_ing');
      changeProductColorByBps({ id });
      $removeClass('cv_print_area cv_mask1 cv_mask2 cv_print_item cv_safety_area', $qs('.size_face_editor'));
      await renderFacesByCurrentBpcfsOnlySizeInMakerA();
      $.remove_class($1('#maker_frame'), 'pc_canvas_change_ing');
      await sizeFaceEditorInit();
    }),
    $delegate('click', '.section.mobile_maker_size_ratio .save', async (e) => {
      const value = go($qs('.section.mobile_maker_size_ratio input'), $val);
      const mobile_maker_size_ratio = parseFloat((value / 100).toFixed(2));
      const base_product = box().maker.product_color._.base_product;
      $.don_loader_start();
      await $.post('/@api/base_product/maker_features_update', {
        maker_features: { mobile_maker_size_ratio },
        base_product_id: base_product.id,
      });
      $.alert('저장됐습니다.');
      $.don_loader_end();
    }),
    $delegate('click', '.section.canvas_size .apply', async (e) => {
      $.don_loader_start();
      const sf_img_width = go(
        e.currentTarget,
        $closest('.canvas_size'),
        $find('[name="sf_img_width"]'),
        $val,
        parseInt,
      );
      const sf_img_height = go(
        e.currentTarget,
        $closest('.canvas_size'),
        $find('[name="sf_img_height"]'),
        $val,
        parseInt,
      );
      const sf_img_diff = go(
        e.currentTarget,
        $closest('.canvas_size'),
        $find('[name="sf_img_diff"]'),
        $val,
        parseInt,
      );
      const sf = getItsSf(getCurrentFcanvas());
      sf.img.width = sf_img_width;
      sf.img.height = sf_img_height;
      sf.img.diff = sf_img_diff;
      if ($.has_class($1('#maker_frame'), 'pc_canvas_change_ing')) return;
      $.add_class($1('#maker_frame'), 'pc_canvas_change_ing');
      $removeClass('cv_print_area cv_mask1 cv_mask2 cv_print_item cv_safety_area', $qs('.size_face_editor'));
      await renderFacesByCurrentBpcfsOnlySizeInMakerA();
      $.remove_class($1('#maker_frame'), 'pc_canvas_change_ing');
      $.don_loader_end();
    }),
    $delegate('click', '.section.canvas_size .save', async (e) => {
      $.don_loader_start();
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      const sf = getItsSf(getCurrentFcanvas());
      await axios.post('/@api/base_product_faces/update_adjust_area', {
        img: sf.img,
        base_product_face_id,
        base_product_size_id,
      });
      $.don_loader_end();
    }),
    $delegate('click', '.section.sticker_previews .save', async (e) => {
      $.don_loader_start();
      const sticker_previews_el = $closest('.sticker_previews')(e.currentTarget);
      const sticker_previews = go(
        sticker_previews_el,
        $findAll('form'),
        map((form_el) => {
          return go(new FormData(form_el).entries(), object, (obj) => {
            obj.option_names = JSON.parse(obj.option_names);
            return obj;
          });
        }),
      );
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      await axios.post('/@api/base_product_faces/update_adjust_area', {
        sticker_previews,
        base_product_face_id,
        base_product_size_id,
      });
      $.don_loader_end();
    }),
    $delegate('click', '.reset_cv_obj_alignment', async (e) => {
      const sf = getItsSf(getCurrentFcanvas());
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      const cv_obj_alignments_attrs = await NewMakerCvObjAlignmentF.editorInit();
      if (!cv_obj_alignments_attrs) return;
      if (cv_obj_alignments_attrs) {
        await axios.post('/@api/base_product_faces/update_adjust_area', {
          cv_obj_alignments_attrs,
          base_product_face_id,
          base_product_size_id,
        });
      }
      sf.cv_obj_alignments_attrs = cv_obj_alignments_attrs;
    }),
    $delegate('click', '.show_all_cv_obj_alignment', async (e) => {
      const fcanvas = getCurrentFcanvas();

      go(
        makeFilterCvObj(getCurrentFcanvas()._objects, 'cv_obj_alignment'),
        each((cv_obj) => cv_obj.set({ visible: !cv_obj.visible })),
      );
      fcanvas.renderAll();
    }),
    $delegate('click', '.other_properties .save', async (e) => {
      $.don_loader_start();
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      const sf = getItsSf(getCurrentFcanvas());
      const form_el = $closest('form')(e.currentTarget);
      await go(
        form_el,
        (el) => new FormData(el),
        (form_dt) => form_dt.entries(),
        object,
        mapObject((v) => {
          const num = parseFloat(v);
          return isNaN(num) ? v || null : num;
        }),
        (obj) => {
          // obj.has_cutting_line = form_el.has_cutting_line.checked;
          obj.is_print_area_legacy = form_el.is_print_area_legacy.checked;
          if (obj.is_print_area_legacy === true) {
            obj.legacy_print_area = sf.legacy_print_area || sf.print.px;
          }
          return obj;
        },
        async (other_properties) => {
          await axios.post('/@api/base_product_faces/update_adjust_area', {
            other_properties,
            base_product_face_id,
            base_product_size_id,
          });
          extend(sf, other_properties);
        },
      );
      $.don_loader_end();
    }),
    $delegate('change', '.section.item input', async (e) => {
      const form_el = $closest('form')(e.currentTarget);
      if (e.currentTarget.name === 'width' || e.currentTarget.name === 'height') {
        const width = form_el.width.value;
        const cm_width = form_el.cm_width.value;
        const cm_height = form_el.cm_height.value;
        form_el.height.value = Math.round((width * cm_height) / cm_width);
      }
      const fcanvas = getCurrentFcanvas();
      const cv_print_item = getCvObj(fcanvas._objects, 'cv_print_item');
      if (cv_print_item) {
        cv_print_item
          .set({
            top: parseInt(form_el.top.value),
            left: parseInt(form_el.left.value),
            width: parseInt(form_el.width.value),
            height: parseInt(form_el.height.value),
          })
          .setCoords();
        fcanvas.renderAll();
      }
    }),
    $delegate('change', '.section.item input.item_rotate', async (e) => {
      $.don_loader_start();
      const rotate_name = e.currentTarget.name;
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      const { items } = getItsSf(getCurrentFcanvas());
      const item = items[0];
      const is_rotate = e.currentTarget.checked;
      const { template_meta } = await $.get('/@api/prerequisite_maker/base_product_size', {
        id: base_product_size_id,
      });
      const current_template_meta = go(
        Array.isArray(template_meta) ? template_meta : [template_meta],
        find((template_meta) =>
          go(
            template_meta.items,
            find((item) => item.base_product_face_id === base_product_face_id),
          ),
        ),
      );
      const cm_width_el = go(e.currentTarget, $closest('.section.item'), $find('[name="cm_width"]'));
      const cm_height_el = go(e.currentTarget, $closest('.section.item'), $find('[name="cm_height"]'));
      if (rotate_name === 'is_90_rotate' || rotate_name === 'is_270_rotate') {
        if (is_rotate) {
          cm_width_el.value = current_template_meta.items[0].height;
          cm_height_el.value = current_template_meta.items[0].width;
        } else {
          cm_width_el.value = current_template_meta.items[0].width;
          cm_height_el.value = current_template_meta.items[0].height;
        }
      }
      item.cm.width = cm_width_el.value;
      item.cm.height = cm_height_el.value;
      const print_item_url_el = go(
        e.currentTarget,
        $closest('.section.item'),
        $find('[name="print_item_url"]'),
      );

      print_item_url_el.value = await go(
        current_template_meta,
        (template_meta) =>
          go(
            template_meta.items,
            find((item) => item.base_product_face_id === base_product_face_id),
          ),
        (template_meta_item) => {
          if (!template_meta_item.print_item_url) return;
          if (is_rotate) {
            return go(
              template_meta_item.print_item_url,
              makeCanvasByUrl,
              rotate_name === 'is_90_rotate'
                ? NewMakerPrintResultF.makeRotate90
                : rotate_name === 'is_180_rotate'
                ? NewMakerPrintResultF.makeRotate180
                : NewMakerPrintResultF.makeRotate270,
              (c) => $.uploadFileToUrl(c.toDataURL('image/png', 1), 'rotated_item', 'PNG'),
              sel('url'),
            );
          } else {
            return template_meta_item.print_item_url;
          }
        },
      );
      item.print_item_url = print_item_url_el.value;
      await renderFacesByCurrentBpcfsOnlySizeInMakerA();
      $.don_loader_end();
    }),
    $delegate('click', '.section.item .save', async (e) => {
      $.don_loader_start();
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      const sf = getItsSf(getCurrentFcanvas());
      const form_el = $closest('form')(e.currentTarget);

      await go(
        form_el,
        (el) => new FormData(el),
        (form_dt) => form_dt.entries(),
        object,
        mapObject((v) => {
          const num = parseFloat(v);
          return isNaN(num) ? v : num;
        }),
        async (_item) => {
          const item = {
            px: { top: _item.top, left: _item.left, width: _item.width, height: _item.height },
            cm: { width: _item.cm_width, height: _item.cm_height },
            is_90_rotate: !!_item.is_90_rotate,
            is_180_rotate: !!_item.is_180_rotate,
            is_270_rotate: !!_item.is_270_rotate,
            print_item_url: _item.print_item_url,
          };

          const px_per_1cm = parseFloat((item.px.width / item.cm.width).toFixed(5));
          const px_per_1cm_input = $qs('input[name="px_per_1cm"]');
          px_per_1cm_input.value = px_per_1cm;
          await axios.post('/@api/base_product_faces/update_adjust_area', {
            items: [item],
            other_properties: { px_per_1cm },
            base_product_face_id,
            base_product_size_id,
          });
          extend(sf, { items: [item] });

          if (!(await $.confirm('모든 세팅을 다시 합니다.'))) return;
          const { template_meta } = await $.get('/@api/prerequisite_maker/base_product_size', {
            id: base_product_size_id,
          });
          const template_metas = Array.isArray(template_meta) ? template_meta : [template_meta];
          // const print_area = go(
          //   prompt('인쇄 최대 영역은 칼선 템플릿에서 몇 cm 안쪽으로 필요합니까?(top,right,bottom,left)'),
          //   split(','),
          //   map((a) => a.trim()),
          //   map(parseFloat),
          // );
          const template_meta_item = go(
            template_metas,
            flatMap((tm) => tm.items),
            find((item) => item.base_product_face_id == base_product_face_id),
          );
          let print_area_photoshop_data;
          if (
            !Number.isNaN(template_meta_item.print_margin_top) &&
            !Number.isNaN(template_meta_item.print_margin_left) &&
            !Number.isNaN(template_meta_item.print_margin_right) &&
            !Number.isNaN(template_meta_item.print_margin_bottom)
          ) {
            const px = item.px;
            const top = px.top + template_meta_item.print_margin_top * px_per_1cm;
            const left = px.left + template_meta_item.print_margin_left * px_per_1cm;
            const width =
              px.width -
              (template_meta_item.print_margin_left + template_meta_item.print_margin_right) * px_per_1cm;
            const height =
              px.height -
              (template_meta_item.print_margin_top + template_meta_item.print_margin_bottom) * px_per_1cm;
            const ratio = 2;
            print_area_photoshop_data = `최대 인쇄 영역:[x:${Math.round(left * ratio)}, y:${Math.round(
              top * ratio,
            )}, width:${Math.round(width * ratio)}, height:${Math.round(
              height * ratio,
            )}, 선 두깨는 최소:${Math.ceil(ratio)}]`;
            sf.print.px.top = Math.round(top);
            sf.print.px.left = Math.round(left);
            sf.print.px.width = Math.round(width);
            sf.print.px.height = Math.round((sf.print.px.width * height) / width);
            sf.print.cm.width =
              item.cm.width - (template_meta_item.print_margin_left + template_meta_item.print_margin_right);
            sf.print.cm.width = parseFloat(sf.print.cm.width.toFixed(4));
            sf.print.cm.height =
              item.cm.height - (template_meta_item.print_margin_top + template_meta_item.print_margin_bottom);
            sf.print.cm.height = parseFloat(sf.print.cm.height.toFixed(4));
            await axios.post('/@api/base_product_faces/update_adjust_area', {
              print: sf.print,
              base_product_face_id,
              base_product_size_id,
            });
          }
          // const product_area = go(
          //   prompt('재단 영역은 칼선 템플릿에서 몇 cm 안쪽으로 필요합니까?(top,right,bottom,left)'),
          //   split(','),
          //   map((a) => a.trim()),
          //   map(parseFloat),
          // );
          let product_area_photoshop_data;
          if (
            !Number.isNaN(template_meta_item.product_margin_top) &&
            !Number.isNaN(template_meta_item.product_margin_left) &&
            !Number.isNaN(template_meta_item.product_margin_right) &&
            !Number.isNaN(template_meta_item.product_margin_bottom)
          ) {
            const px = item.px;
            const top = px.top + template_meta_item.product_margin_top * px_per_1cm;
            const left = px.left + template_meta_item.product_margin_left * px_per_1cm;
            const width =
              px.width -
              (template_meta_item.product_margin_left + template_meta_item.product_margin_right) * px_per_1cm;
            const height =
              px.height -
              (template_meta_item.product_margin_top + template_meta_item.product_margin_bottom) * px_per_1cm;
            const ratio = 2;
            product_area_photoshop_data = `재단 영역:[x:${Math.round(left * ratio)}, y:${Math.round(
              top * ratio,
            )}, width:${Math.round(width * ratio)}, height:${Math.round(
              height * ratio,
            )}, 선 두깨는 최소:${Math.ceil(ratio)}]`;
          }
          //
          // const safety_area = go(
          //   prompt('안전 영역은 칼선 템플릿에서 몇 cm 안쪽으로 필요합니까?(top,right,bottom,left)'),
          //   split(','),
          //   map((a) => a.trim()),
          //   map(parseFloat),
          // );
          let safety_area_photoshop_data;
          if (
            !Number.isNaN(parseFloat(template_meta_item.safety_margin_top)) &&
            !Number.isNaN(parseFloat(template_meta_item.safety_margin_left)) &&
            !Number.isNaN(parseFloat(template_meta_item.safety_margin_right)) &&
            !Number.isNaN(parseFloat(template_meta_item.safety_margin_bottom))
          ) {
            sf.safety = sf.safety || { px: {}, cm: {} };
            const px = item.px;
            const top = px.top + template_meta_item.safety_margin_top * px_per_1cm;
            const left = px.left + template_meta_item.safety_margin_left * px_per_1cm;
            const width =
              px.width -
              (template_meta_item.safety_margin_left + template_meta_item.safety_margin_right) * px_per_1cm;
            const height =
              px.height -
              (template_meta_item.safety_margin_top + template_meta_item.safety_margin_bottom) * px_per_1cm;
            const ratio = 2;
            safety_area_photoshop_data = `안전 영역:[x:${Math.round(left * ratio)}, y:${Math.round(
              top * ratio,
            )}, width:${Math.round(width * ratio)}, height:${Math.round(
              height * ratio,
            )}, 선 두깨는 최소:${Math.ceil(ratio)}]`;
            sf.safety.px.top = Math.round(top);
            sf.safety.px.left = Math.round(left);
            sf.safety.px.width = Math.round(width);
            sf.safety.px.height = Math.round((sf.safety.px.width * height) / width);
            sf.safety.cm.width =
              item.cm.width -
              (template_meta_item.safety_margin_left + template_meta_item.safety_margin_right);
            sf.safety.cm.width = parseFloat(sf.safety.cm.width.toFixed(4));
            sf.safety.cm.height =
              item.cm.height -
              (template_meta_item.safety_margin_top + template_meta_item.safety_margin_bottom);
            sf.safety.cm.height = parseFloat(sf.safety.cm.height.toFixed(4));
            await axios.post('/@api/base_product_faces/update_adjust_area', {
              safety: sf.safety,
              base_product_face_id,
              base_product_size_id,
            });
          }
          const cv_obj_alignments_area = go(
            prompt('정렬 아이템은 칼선 템플릿에서 몇 cm 안쪽으로 필요합니까?(top,right,bottom,left)'),
            split(','),
            map((a) => a.trim()),
            map(parseFloat),
          );
          if (cv_obj_alignments_area.length === 4) {
            const px = item.px;
            const top = px.top + cv_obj_alignments_area[0] * px_per_1cm;
            const left = px.left + cv_obj_alignments_area[3] * px_per_1cm;
            const width = px.width - (cv_obj_alignments_area[1] + cv_obj_alignments_area[3]) * px_per_1cm;
            const height = px.height - (cv_obj_alignments_area[0] + cv_obj_alignments_area[2]) * px_per_1cm;
            sf.cv_obj_alignments_attrs = [
              {
                top,
                left,
                width,
                height,
              },
            ];
            await axios.post('/@api/base_product_faces/update_adjust_area', {
              cv_obj_alignments_attrs: sf.cv_obj_alignments_attrs,
              base_product_face_id,
              base_product_size_id,
            });
          }
          go(
            [print_area_photoshop_data, product_area_photoshop_data, safety_area_photoshop_data],
            compact,
            each(alert),
          );
          await $.alert('새로고침 합니다.');
          window.location.reload();
        },
      );
      $.don_loader_end();
    }),
    $delegate('click', '.adjust_area_size form[data-obj="px"] .apply', async (e) => {
      $.don_loader_start();
      const cv_type = go(e.currentTarget, $closest('.adjust_area_size'), (a) => a.dataset.cv_type);
      $removeClass('cv_print_area cv_mask1 cv_mask2 cv_print_item cv_safety_area', $qs('.size_face_editor'));
      const [px, cm, result_px, etc_meta, img] = go(
        e.currentTarget,
        $closest('.section'),
        (section) => [
          $find('form[data-obj="px"]')(section),
          $find('form[data-obj="cm"]')(section),
          $find('form[data-obj="result_px"]')(section),
          $find('form[data-obj="etc_meta"]')(section),
          $find('form[data-obj="img"]')(section),
        ],
        map((el) =>
          go(
            new FormData(el).entries(),
            map(([k, v]) => {
              if (!v) return [k, null];
              if (Number.isNaN(parseFloat(v))) {
                if (v.includes('{') || v.includes('[')) return [k, JSON.parse(v)];
                else return [k, v];
              } else {
                return [k, parseFloat(parseFloat(v).toFixed(2))];
              }
            }),
            object,
          ),
        ),
      );
      const adjust_area = {
        px,
        result_px,
        cm,
        etc_meta,
        img,
      };
      adjust_area.etc_meta = etc_meta.etc_meta;
      $.don_loader_start();
      sf[cv_type === 'cv_print_area' ? 'print' : 'safety'] = adjust_area;
      $.don_loader_end();
      await renderFacesByCurrentBpcfsOnlySizeInMakerA();
      $.don_loader_end();
    }),
    $delegate('change', '.adjust_area_size form[data-obj="px"] input', async (e) => {
      const fcanvas = getCurrentFcanvas();
      const cv_obj = getCvObj(
        fcanvas._objects,
        go(e.currentTarget, $closest('.section'), (el) => el.dataset.cv_type),
      );
      const form_el = $closest('form')(e.currentTarget);
      const name = e.currentTarget.name;
      const value = Math.round(e.currentTarget.value);
      const cm_el = go(e.currentTarget, $closest('.adjust_area_size'), $find('form[data-obj="cm"]'));
      const ratio_width = go(cm_el, $find('input[name="width"]'), $val, parseFloat);
      const ratio_height = go(cm_el, $find('input[name="height"]'), $val, parseFloat);
      form_el[name].value = value;
      if (ratio_width > 0 && ratio_height > 0 && name === 'width') {
        form_el.height.value = ratioCalcHeight({ width: ratio_width, height: ratio_height }, value);
      } else if (ratio_width > 0 && ratio_height > 0 && name === 'height') {
        form_el.width.value = ratioCalcWidth({ width: ratio_width, height: ratio_height }, value);
      }
      go(new FormData(form_el).entries(), object, mapObject(parseInt), plusStrokeWidth, (size) =>
        cv_obj.set(size),
      );
      cv_obj.setCoords().canvas.renderAll();
    }),
    $delegate('click', '.to_center', (e) => {
      const form_el = $closest('form')(e.currentTarget);
      const px_width = form_el.width.value;
      form_el.left.value = Math.round((G.mp.maker.CANVAS_WIDTH_ORIGIN - px_width) / 2);
      const fcanvas = getCurrentFcanvas();
      const cv_print_item = getCvObj(fcanvas._objects, 'cv_print_item');
      if (cv_print_item) {
        cv_print_item
          .set({
            top: parseInt(form_el.top.value),
            left: parseInt(form_el.left.value),
            width: parseInt(form_el.width.value),
            height: parseInt(form_el.height.value),
          })
          .setCoords();
        fcanvas.renderAll();
      }
    }),
    $delegate('click', '.mask1', async (e) => {
      const img_size = await getTargetSizeByAttr(getCvObj(getCurrentFcanvas()._objects, 'cv_mask1').toJSON());
      go(e.currentTarget, $closest('form'), (form_el) => {
        form_el.top.value = img_size.top;
        form_el.left.value = img_size.left;
        form_el.width.value = img_size.width;
        $trigger('change', form_el.width);
      });
    }),
    $delegate('click', '.mask2', async (e) => {
      const img_size = await getTargetSizeByAttr(getCvObj(getCurrentFcanvas()._objects, 'cv_mask2').toJSON());
      go(e.currentTarget, $closest('form'), (form_el) => {
        form_el.top.value = img_size.top;
        form_el.left.value = img_size.left;
        form_el.width.value = img_size.width;
        $trigger('change', form_el.width);
      });
    }),
    $delegate('change', '.adjust_area_size .result_px input[name="width"]', (e) => {
      const width = e.currentTarget.value;
      const { dpi } = getItsSf(getCurrentFcanvas());
      go(
        e.currentTarget,
        $closest('.adjust_area_size'),
        $find('.cm input[name="width"]'),
        $setVal(((width / dpi) * 2.54).toFixed(1)),
      );
    }),
    $delegate('change', '.adjust_area_size .result_px input[name="height"]', (e) => {
      const height = e.currentTarget.value;
      const { dpi } = getItsSf(getCurrentFcanvas());
      go(
        e.currentTarget,
        $closest('.adjust_area_size'),
        $find('.cm input[name="height"]'),
        $setVal(((height / dpi) * 2.54).toFixed(1)),
      );
    }),
    $delegate('click', '.adjust_area_size .save', async (e) => {
      const [px, cm, result_px, etc_meta, img] = go(
        e.currentTarget,
        $closest('.section'),
        (section) => [
          $find('form[data-obj="px"]')(section),
          $find('form[data-obj="cm"]')(section),
          $find('form[data-obj="result_px"]')(section),
          $find('form[data-obj="etc_meta"]')(section),
          $find('form[data-obj="img"]')(section),
        ],
        map((el) =>
          go(
            new FormData(el).entries(),
            map(([k, v]) => {
              if (!v) return [k, null];
              if (Number.isNaN(parseFloat(v))) {
                if (v.includes('{') || v.includes('[')) return [k, JSON.parse(v)];
                else return [k, v];
              } else {
                return [k, parseFloat(v)];
              }
            }),
            object,
          ),
        ),
      );
      const adjust_area = {
        px,
        result_px,
        cm,
        etc_meta,
        img,
      };
      adjust_area.etc_meta = etc_meta.etc_meta;
      const { id: base_product_face_id } = getCurrentBpfInMaker();
      const base_product_size_id = getCurrentBpsId();
      const sf = getItsSf(getCurrentFcanvas());

      const fcanvas = getCurrentFcanvas();
      const cv_obj = getCvObj(
        fcanvas._objects,
        go(e.currentTarget, $closest('.section'), (el) => el.dataset.cv_type),
      );
      $.don_loader_start();
      if (cv_obj._data.cv_type === 'cv_print_area') {
        const px_per_1cm_input = go(
          e.currentTarget,
          $closest('.size_face_editor'),
          $find('input[name="px_per_1cm"]'),
        );
        const px_per_1cm = parseFloat((adjust_area.px.width / adjust_area.cm.width).toFixed(5));
        $setVal(px_per_1cm, px_per_1cm_input);
        await axios.post('/@api/base_product_faces/update_adjust_area', {
          print: adjust_area,
          other_properties: { px_per_1cm },
          base_product_face_id,
          base_product_size_id,
        });
        sf.print = adjust_area;
        sf.px_per_1cm = px_per_1cm;
      } else if (cv_obj._data.cv_type === 'cv_safety_area') {
        await axios.post('/@api/base_product_faces/update_adjust_area', {
          safety: adjust_area,
          base_product_face_id,
          base_product_size_id,
        });
        sf.safety = adjust_area;
      }
      $.don_loader_end();
      $.alert('저장됐습니다.');
    }),
    $delegate('change', '[type="file"]', async (e) => {
      $.don_loader_start();
      const bp = getBaseProductInMaker();
      const { url } = await $.upload(e.currentTarget, {
        data: { attached_type: 'base_product_size_face', attached_id: bp.id },
      });
      go(e.currentTarget, $closest('.file_wrapper'), $find('[type="text"]'), (el) => (el.value = url));
      $.don_loader_end();
    }),
    $delegate('click', 'button.general.show_cv_obj', async (e) => {
      $.don_loader_start();
      const cv_obj_name = e.currentTarget.dataset.cv_obj;
      const size_face_editor_el = $qs('.size_face_editor');
      if ($.has_class(size_face_editor_el, cv_obj_name)) {
        $removeClass(cv_obj_name)(size_face_editor_el);
        const canvas = G.mp.maker.editing_canvas();
        const cv_obj = getCvObj(canvas._objects, cv_obj_name);
        cv_obj.visible = false;
        canvas.renderAll();
      } else {
        $addClass(cv_obj_name)(size_face_editor_el);
        const canvas = G.mp.maker.editing_canvas();
        const cv_obj = getCvObj(canvas._objects, cv_obj_name);
        cv_obj.visible = true;
        canvas.renderAll();
      }
      $.don_loader_end();
    }),
    $delegate('click', 'button.mask.show_cv_obj', async (e) => {
      $.don_loader_start();
      const cv_obj_name = e.currentTarget.dataset.cv_obj;
      const size_face_editor_el = $qs('.size_face_editor');
      if ($.has_class(size_face_editor_el, cv_obj_name)) {
        $removeClass(cv_obj_name)(size_face_editor_el);
        const canvas = G.mp.maker.editing_canvas();
        const cv_mask1 = getCvObj(canvas._objects, cv_obj_name);
        cv_mask1.globalCompositeOperation = 'destination-in';
        cv_mask1.visible = false;
        canvas.renderAll();
      } else {
        $addClass(cv_obj_name)(size_face_editor_el);
        const canvas = G.mp.maker.editing_canvas();
        const cv_mask1 = getCvObj(canvas._objects, cv_obj_name);
        if (!cv_mask1._element) return $.don_loader_end();
        cv_mask1._element = await go(makeCanvasByUrl(cv_mask1._element.src), (c) => {
          const canvas = makeCanvasTextured(c, cv_obj_name === 'cv_mask1' ? '#da00ff' : '#ff0000');
          return makeImageFromUrl(canvas.toDataURL());
        });
        cv_mask1.visible = true;
        cv_mask1.globalCompositeOperation = 'source-over';
        canvas.renderAll();
      }
      $.don_loader_end();
    }),
    $delegate('click', 'button.print_area.show_cv_obj', async (e) => {
      $.don_loader_start();
      const cv_obj_name = e.currentTarget.dataset.cv_obj;
      const size_face_editor_el = $qs('.size_face_editor');
      if ($.has_class(size_face_editor_el, cv_obj_name)) {
        $removeClass(cv_obj_name)(size_face_editor_el);
        const canvas = G.mp.maker.editing_canvas();
        const print_area = getCvObj(canvas._objects, cv_obj_name);
        print_area.visible = false;
        canvas.renderAll();
      } else {
        $addClass(cv_obj_name)(size_face_editor_el);
        const canvas = G.mp.maker.editing_canvas();
        const print_area = getCvObj(canvas._objects, cv_obj_name);
        print_area.visible = true;
        canvas.renderAll();
      }
      $.don_loader_end();
    }),
    $delegate('click', 'button.cv_print_item.show_cv_obj', async (e) => {
      $.don_loader_start();
      const size_face_editor_el = $qs('.size_face_editor');
      const fcanvas = getCurrentFcanvas();
      if ($.has_class(size_face_editor_el, 'cv_print_item')) {
        $removeClass('cv_print_item')(size_face_editor_el);
        const cv_print_item = getCvObj(fcanvas._objects, 'cv_print_item');
        $removeClass('cv_print_item')($1('#maker_frame'));
        fcanvas.remove(cv_print_item);
      } else {
        $addClass('cv_print_item')(size_face_editor_el);
        await makeCvPrintItem();
        // const cv_obj = getCvObj(fcanvas._objects, 'cv_print_item');
        // G.mp.maker.selected_pass = true;
        // G.mp.maker.multiple_select(cv_obj);
        // G.mp.maker.selected_pass = false;
      }
      $.don_loader_end();
    }),
  );
};
