import { filter, go, head, sel, sortBy, strMap } from 'fxjs/es';
import { MPS_SMS_TYPE, SHOPFREECA_TEL } from '../../../../Creator/Sms/S/constant.js';
import { MarketplaceConstantS } from '../../../../Marketplace/S/Constant/module/MarketplaceConstantS.js';
import { checkPhoneNumberSms } from '../../../../Sms/Fs/S/fs.js';
import {
  NESS_CUSTOMER_TEL,
  NESS_PRIME_CUSTOMER_TEL,
  NESS_SMS_TYPE,
} from '../../../../Sms/Ness/B/constant.js';
import { legacyHtml } from '../../../../Util/S/Function/util.js';
import { message_collections } from './message_collections.js';

export const makeSmsSendFormDFHtml = ({ title, countries, receive_number, collabo_type, projection }) => {
  const is_ness = go(sel('_.projection._.projections', projection), head, (_p) => {
    return _p?._?.store?._?.crew?.marketplace_name === 'NESS';
  });

  const sms_type = go(void 0, () => {
    if (is_ness) {
      return 'NESS';
    } else if (collabo_type == 'creator') {
      return 'creator';
    } else if (collabo_type == '') {
      return '';
    }
  });

  return legacyHtml`
    <div
      class="ks_send_area"
      sms_type="${sms_type}"
    >
      <h3>${title}</h3>
      <div class="control">
        <span class="head">보내는 번호</span>
        <label class="radio">
          <input
            type="radio"
            name="sms_type"
            class="radio_sms_type"
            value=""
            ${!collabo_type || collabo_type == '' ? ' checked' : ''}
          />
          마플(1566-9437)
        </label>
  
        <label class="radio">
          <input
            type="radio"
            name="sms_type"
            class="radio_sms_type"
            value="creator"
            ${collabo_type && collabo_type == 'creator' && !is_ness ? ' checked' : ''}
          />
          마플샵(1566-5496)
        </label>
  
        <label class="radio">
          <input
            class="radio_sms_type"
            type="radio"
            name="sms_type"
            value="${MarketplaceConstantS.Marketplaces.NESS}"
            ${is_ness ? ' checked' : ''}
          />
          NESS (${NESS_CUSTOMER_TEL})
        </label>
        
        <label class="radio" style="display: none;">
          <input class="radio_sms_type" type="radio" name="sms_type" value="direct" />
          직접입력
        </label>
  
        <label class="radio">
          <input
            class="radio_sms_type"
            type="radio"
            name="sms_type"
            value="${NESS_SMS_TYPE.NESS_PRIME}"
            ${is_ness ? ' checked' : ''}
          />
          NEXON PRIME (${NESS_PRIME_CUSTOMER_TEL})
        </label>
  
        <label class="radio">
          <input
            class="radio_sms_type"
            type="radio"
            name="sms_type"
            value="${MPS_SMS_TYPE.SHOPFREECA}"
          />
          샵프리카 (${SHOPFREECA_TEL})
        </label>
        <label class="radio" style="display: none;">
          <input class="radio_sms_type" type="radio" name="sms_type" value="direct" />
          직접입력
        </label>
      </div>
      <div class="control caller_number_area">
        <label>
          <span class="head">보내는 사람 번호</span>
          <input type="text" placeholder="02" value="" class="caller_number1" />
          <span class="hyphen">-</span>
          <input type="text" placeholder="123" value="" class="caller_number2" />
          <span class="hyphen">-</span>
          <input type="text" placeholder="1234" value="" class="caller_number3" />
        </label>
      </div>
      <div class="control receive_area">
        <span class="head">받는 사람 번호</span>
        <select name="dialing_code" class="dialing_code">
          <option value="82" code="82">+82, Korea, Republic of</option>
          ${go(
            countries,
            sortBy((country) => parseInt(country.dialing_code)),
            strMap(
              (country) => `
                <option value="${country.dialing_code || ''}" code="${country.code}">
                  +${country.dialing_code}, ${country.name}
                </option>
              `,
            ),
          )}
        </select>
        <input type="text" name="receive_num" value="" />
        <button type="button" class="add_number">추가</button>
        <ul class="receive_list">
          ${
            receive_number && checkPhoneNumberSms(receive_number)
              ? makeSmsSendReceiveItemDFHtml(receive_number)
              : ''
          }
        </ul>
        <input
          type="text"
          style="display: none;"
          placeholder=""
          readonly
          value="${receive_number || ''}"
          class="receive_numbers"
        />
      </div>
      <div class="control">
        <label>
          <span class="head">
            내용
            <button type="button" class="btn_add_img">이미지 업로드</button>
            <input type="file" name="sms_img_upload" accept=".jpg,.png,.jpeg,.gif" style="display: none;" />
          </span>
          <div class="msg_select_boxes">
            <select class="msg_select_box" style="margin-right: 8px; margin-bottom: 8px;">
              <option value="">마플</option>
              ${go(
                message_collections,
                filter((v) => v.service_name === '마플'),
                strMap(
                  (v) => legacyHtml`
                  <option value="${v.id}">${v.service_name}-${v.title}${
                    v.sub_title ? '-' + v.sub_title : ''
                  }</option>
                `,
                ),
              )}
            </select>
            <select class="msg_select_box" style="margin-right: 8px; margin-bottom: 8px;">
              <option value="">마플샵</option>
              ${go(
                message_collections,
                filter((v) => v.service_name === '마플샵'),
                strMap(
                  (v) => legacyHtml`
                  <option value="${v.id}">${v.service_name}-${v.title}-${v.sub_title}</option>
                `,
                ),
              )}
            </select>
            <select class="msg_select_box">
              <option value="">넥슨 에센셜</option>
              ${go(
                message_collections,
                filter((v) => v.service_name === '넥슨 에센셜'),
                strMap(
                  (v) => legacyHtml`
                  <option value="${v.id}">${v.service_name}-${v.title}-${v.sub_title}</option>
                `,
                ),
              )}
            </select>
          </div>
          <textarea cols="30" rows="12" name="sms_body" placeholder="내용"></textarea>
        </label>
        <p class="byte">
          <span class="byte_txt">0</span>
          <span>/90</span>
          <span class="txt1" style="display: none;">장문문자(lms)로 변경 됩니다.</span>
        </p>
      </div>
      <div class="buttons">
        <button type="button" class="btn_send">전송</button>
      </div>
    </div>
  `;
};

export const makeSmsSendReceiveItemDFHtml = (number) => legacyHtml`
  <li>
    <span class="num">${checkPhoneNumberSms(number) ? `82-${number.substring(1)}` : number}</span>
    <span class="btn_del">X</span>
  </li>
`;

export const makeSmsProjectionItemHtml = (sms) => legacyHtml`
  <li>
    <div class="caller">
      <span class="number">보낸 사람 번호: ${sms.caller_number}</span>
      <span class="send_time">보낸 시간: ${moment(sms.created_at).format('YYYY.MM.DD HH:mm')}</span>
    </div>
    <div class="receiver">
      <span class="number">받는 사람 번호: ${sms.receive_number}</span>
    </div>
    <div class="sms_text">
      <div class="head">보낸 내용:</div>
      <div class="text">${sms.text}</div>
    </div>
  </li>
`;
